.customer-services-card {
  cursor: pointer;
  min-height: 5em;
  border: solid 1px #ddd;
  border-radius: 1em;
  font-size: 12px;
  margin-bottom: 5px;
  background-color: #eee;
  padding-left: 10px;
  padding-right: 10px;
}
.customer-services-card:hover {
  background-color: #aaa;
}

.customer-services-price {
  display: flex;
  padding-top: 5px;
}
.customer-services-price .item-name {
  width: 60%;
}
.customer-services-price .item-price {
  width: 40%;
  text-align: right;
}
.customer-services-card .item-name { 
  // grid-area: name; 
  font-size: 15px;
  font-weight: bold;
  color: #000;
  // align-self: end;
}
.customer-services-card .item-price { 
  // grid-area: price; 
  font-size: 14px;
  color: #000;
  // text-align: end;
  // align-self: center;
  font-weight: bold;
  position: relative;
}
.customer-services-card .item-description { 
  grid-area: description; 
  color: #000;
  padding-top: 4px;
}


.customer-invoices-card {
  border: solid 1px #ddd;
  border-radius: 1em;
  display: grid;
  grid-template-columns: 60% auto auto;
  grid-template-areas:
    'name name button'
    'description description price';
  gap: 1px;
  font-size: 12px;
  margin-bottom: 5px;
  background-color: #eee;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 5em;
}

.customer-invoices-card .item-name { 
  grid-area: name; 
  font-size: 15px;
  font-weight: bold;
  color: #000;
  align-self: end;
}
.customer-invoices-card .item-price { 
  grid-area: price; 
  font-size: 14px;
  color: #000;
  text-align: end;
  align-self: start;
  font-weight: bold;
  padding-right: 2px;
}
.customer-invoices-card .item-description { 
  grid-area: description; 
  color: #000;
}
.customer-invoices-card .item-button { 
  grid-area: button; 
  // border-radius: 5px; 
  background-color: transparent;
  align-self: start;
  text-align: right;
  cursor: pointer;
}

// COSTS
.customer-costs-card {
  border: solid 1px #ddd;
  border-radius: 1em;
  display: grid;
  grid-template-columns: 42px auto auto auto;
  grid-template-areas:
    'inspector inspector inspector button'
    'avatar name name price'
    'avatar description description price';
    // 'avatar description description price';
  gap: 1px;
  font-size: 12px;
  margin-bottom: 5px;
  background-color: #eee;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 7em;
}

.customer-costs-card .item-name { 
  grid-area: name; 
  font-size: 12px;
  font-weight: bold;
  color: #000;
  align-self: end;
  margin-left: 12px;
}
.customer-costs-card .item-price { 
  grid-area: price; 
  font-size: 14px;
  color: #000;
  text-align: end;
  align-self: start;
  font-weight: bold;
  padding-right: 2px;
}
.customer-costs-card .item-description { 
  grid-area: description; 
  color: #000;
  margin-left: 12px;
}
.customer-costs-card .item-inspector { 
  margin-top: 10px;
  margin-left: 2px;
  grid-area: inspector; 
  font-size: 13px;
  // font-weight: bold;
  color: #000;
}
.customer-costs-card .item-button { 
  grid-area: button; 
  // border-radius: 5px; 
  background-color: transparent;
  align-self: start;
  text-align: right;
  cursor: pointer;
}
.customer-costs-card .item-avatar { 
  grid-area: avatar; 
  // border-radius: 5px; 
  background-color: transparent;
  align-self: center;
  text-align: right;
  cursor: pointer;
}



.customer-service-table-formgroup {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 1em;
  margin-right: 1px;
  cursor: pointer;
}
.customer-service-table-formgroup:hover {
  background-color: #ccc;
}
.customer-service-table-header {
  color: #fff;
  font-weight: bold;
  background-color: rgb(60, 75, 100);
}
.customer-service-table-body {
  font-size: 13px;
  color: #000;
}
.customer-service-table-ccol {
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 2px #ddd
}

.customer-service-card {
  margin-left: 1em;
  margin-right: 1px;
  display: flex;
  justify-content: space-between;
}

.customer-service-card-title {
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // padding: 10%;
  height: 48px;
  width: 32%;
  font-size: 16px;
}

.customer-service-card-price {
  background-color: rgb(2, 99, 2);
}
.customer-service-card-cost {
  background-color: rgb(131, 0, 0);
}
.customer-service-card-prefit {
  background-color: rgb(2, 51, 126);
}

.containerWidgetDropdownCustomer {
  display: flex;
    padding-left: 1em;
    padding-top: 12px;
    padding-right: 1em;
    padding-bottom: 3px;
    justify-content: space-between;
    /* margin-bottom: 1em; */
    height: 7em
}
.brd-rad {
  border-radius: 5px;
}
.widget-plr {
  padding: 0 3px;
}
.swiper-slide:last-child {
  margin-right: 0px !important;
}