// CUSTOMERS
.inspector-card {
  border-color: #ddd;
  border-style: solid;
  border-radius: 1em 0 0 1em;
  border-width: 1px 0 1px 1px;
  display: grid;
  width: 70%;
  grid-template-columns: 42px auto auto;
  grid-template-areas:
    'avatar name name'
    'avatar description description';
    // 'avatar description description price';
  gap: 1px;
  font-size: 12px;
  background-color: #eee;
  padding-left: 10px;
  padding-right: 10px;
  height: 80px;
  margin-top: -5px;
  cursor: pointer;
}
.inspector-button {
  border-color: #ddd;
  border-style: solid;
  border-radius: 0 1em 1em 0;
  border-width: 1px 1px 1px 0;
  display: flex;
  width: 30%;
  gap: 1px;
  font-size: 12px;
  background-color: #eee;
  height: 80px;
  margin-top: -5px;
  cursor: pointer;
  padding: 15px 15px 0px 0px;
  justify-content: end;
}
.inspector-button button {
  font-size: 14px;
  padding: 5px;
  margin: 0;
  align-self: start;
  min-height: 14px;
  width: 65%;
  align-content: end;
}
.inspector-card .item-name { 
  margin-top: 5px;
  margin-bottom: 3px;
  margin-left: 2px;
  grid-area: name; 
  font-size: 15px;
  font-weight: bold;
  color: #000;
  align-self: end;
  margin-left: 12px;
}
.inspector-card .item-btn { 
  grid-area: btn; 
  align-self: center;
}
.inspector-card .item-price { 
  grid-area: price; 
  font-size: 14px;
  color: #000;
  text-align: end;
  align-self: start;
  font-weight: bold;
  padding-right: 2px;
}
.inspector-card .item-description { 
  grid-area: description; 
  color: #000;
  margin-left: 13px;
  font-size: 13px;
}
.inspector-card .item-avatar { 
  grid-area: avatar; 
  // border-radius: 5px; 
  background-color: transparent;
  align-self: center;
  text-align: right;
  cursor: pointer;
}

.appointment-body {
  margin-left: 1em;
  margin-right: 1em;
}
.appointment-modal-body {
  color: #000;
}
.appointment-modal-header {
  color: #fff;
  font-weight: bold;
  background-color: rgb(60, 75, 100);
}
.appointment-modal-formgroup {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.appointment-modal-formgroup-cursor {
  cursor: pointer
}
.appointment-modal-formgroup-cursor:hover {
  background-color: #eeeeee;
}

.appointment-modal-ccol {
  padding-top: 10px;
  padding-bottom: 10px;
  border: solid 2px #ddd
}
.appointment-modal-button {
  background-color: #0077CC;
  color: #fff;
  text-align: center;
}

.inspector-card-unassigned {
  border: solid 1px #ddd;
  border-radius: 1em;
  gap: 1px;
  background-color: #eee;
  padding-left: 10px;
  padding-right: 10px;
  height: 80px;
  margin-top: -5px;
  cursor: pointer;
  align-content: center;
  color: rgb(0, 89, 253);
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 18px;
  justify-content: center;
}
.customer-custom-radio {
  text-align: center;
}