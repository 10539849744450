// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}
.c-sidebar .c-sidebar-brand{
    text-decoration: none;
}

.c-dark-theme .card {
  background-color: #24252f;
  border-color: #181924;
  // box-shadow: 0 1px 1px 0 rgba(var(--elevation-base-color), 0.14),
  //   0 2px 1px -1px rgba(var(--elevation-base-color), 0.12),
  //   0 1px 3px 0 rgba(var(--elevation-base-color), 0.2);
}

.card[class^='bg-'],
.card[class*=' bg-'] {
  border-color: transparent;
}

.c-dark-theme .c-sidebar {
  color: #e1e1e1;
  background: #2a2b36;
  border: 0;
}
.card-header {
  color: #000;
}
.c-dark-theme .card-header {
  background-color: #282933;
  border-color: rgba(255, 255, 255, 0.075);
}

.c-dark-theme .table td,
.c-dark-theme .table th {
  border-top-color: hsla(0, 0%, 100%, 0.1);
}

.c-dark-theme .c-header {
  background: #2a2b36;
  border-bottom: 0;
}

.c-dark-theme .c-header .c-subheader {
  margin-top: -1px;
  border-top: 1px solid #181924;
}

.c-dark-theme .c-subheader {
  background: #2a2b36;
  border-bottom: 0;
}

.c-dark-theme .c-header .c-header-brand {
  color: #fff;
  background-color: transparent;
}

.c-dark-theme .c-header .c-header-nav .c-header-nav-btn,
.c-dark-theme .c-header .c-header-nav .c-header-nav-link {
  color: hsla(0, 0%, 100%, 0.75);
}

.c-dark-theme a {
  color: #7267d3;
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.c-dark-theme a:hover {
  color: #4638c2;
  text-decoration: none;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.6);
}

.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar .c-sidebar-nav-link {
  color: hsla(0, 0%, 100%, 0.8);
  background: transparent;
}

.c-dark-theme .dropdown-item:focus,
.c-dark-theme .dropdown-item:hover {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.c-dark-theme .c-sidebar .c-sidebar-brand,
.c-dark-theme .c-sidebar .c-sidebar-header {
  background: rgba(0, 0, 21, 0.2);
}

.c-dark-theme .c-header .c-header-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.75)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.c-dark-theme .c-header .c-header-toggler-icon:hover {
  background-image: url('data:image/svg+xml;charset=utf8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMzAgMzAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggc3Ryb2tlPSdyZ2JhKDI1NSwyNTUsMjU1LDAuOSknIHN0cm9rZS13aWR0aD0nMi4yNScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbWl0ZXJsaW1pdD0nMTAnIGQ9J000IDdoMjJNNCAxNWgyMk00IDIzaDIyJy8+PC9zdmc+');
}

.c-dark-theme .c-subheader .c-subheader-nav .c-subheader-nav-btn,
.c-dark-theme .c-subheader .c-subheader-nav .c-subheader-nav-link {
  color: hsla(0, 0%, 100%, 0.75);
}

.c-dark-theme .c-subheader .c-subheader-nav .c-subheader-nav-btn:focus,
.c-dark-theme .c-subheader .c-subheader-nav .c-subheader-nav-btn:hover,
.c-dark-theme .c-subheader .c-subheader-nav .c-subheader-nav-link:focus,
.c-dark-theme .c-subheader .c-subheader-nav .c-subheader-nav-link:hover {
  color: hsla(0, 0%, 100%, 0.9);
}

.c-dark-theme .breadcrumb {
  background-color: transparent;
  border-color: #1e1e29;
}
.c-dark-theme .card-footer {
  background-color: #282933;
  border-color: rgba(255, 255, 255, 0.075);
}

.c-dark-theme .progress {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.c-dark-theme .progress-bar {
  color: #fff;
  background-color: #4638c2;
}
.c-dark-theme .table-outline {
  border-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .table {
  color: #e1e1e1;
  background-color: transparent;
}
.c-dark-theme .table .thead-light th {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.05);
  border-color: hsla(0, 0%, 100%, 0.1);
}

.c-dark-theme .table thead th {
  border-bottom-color: hsla(0, 0%, 100%, 0.1);
}

.c-dark-theme .c-footer {
  color: #000005;
  background: #1e1e29;
  border-top: 1px solid #181924;
}

.c-dark-theme .c-header .c-header-toggler {
  color: hsla(0, 0%, 100%, 0.75);
  border-color: hsla(0, 0%, 100%, 0.1);
}

.c-dark-theme .dropdown-menu {
  color: #e1e1e1;
  background-color: #34343b;
  border-color: #181924;
}

.c-dark-theme .dropdown-header {
  color: #e1e1e1;
}

.c-dark-theme .dropdown-item {
  color: #e1e1e1;
}

.c-dark-theme .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #181924;
}

.c-header-nav .dropdown-item {
  min-width: 180px;
}

.c-dark-theme .c-header .c-header-nav .c-header-nav-btn:focus,
.c-dark-theme .c-header .c-header-nav .c-header-nav-btn:hover,
.c-dark-theme .c-header .c-header-nav .c-header-nav-link:focus,
.c-dark-theme .c-header .c-header-nav .c-header-nav-link:hover {
  color: hsla(0, 0%, 100%, 0.9);
}

.c-dark-theme .c-header .c-header-nav .c-header-nav-btn,
.c-dark-theme .c-header .c-header-nav .c-header-nav-link {
  color: hsla(0, 0%, 100%, 0.75);
}
// @media not all, (hover: hover) {
//   .c-dark-theme a:hover {
//     color: #7267d3;
//   }
// }

.c-dark-theme .form-control {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-color: hsla(0, 0%, 100%, 0.15);
  &::placeholder,
  &::-webkit-input-placeholder {
    color: hsla(0, 0%, 88.2%, 0.25);
    opacity: 1;
  }
}

.c-dark-theme .custom-select {
  color: #e1e1e1;
  background: hsla(0, 0%, 100%, 0.1)
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23636f83' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center/8px 10px;
  border-color: rgba(0, 0, 21, 0.3);
  & option {
    background-color: #393a42;
  }
}

.c-dark-theme .form-control:focus {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.15);
  border-color: hsla(0, 0%, 100%, 0.2);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
}

.c-dark-theme .form-control:disabled,
.c-dark-theme .form-control[readonly] {
  background-color: hsla(0, 0%, 100%, 0.05);
}

.c-dark-theme .custom-select:disabled {
  color: #8a93a2;
  background-color: hsla(0, 0%, 100%, 0.05);
}

.c-dark-theme .custom-control-label:before {
  background-color: hsla(0, 0%, 100%, 0.1);
  border-color: rgba(0, 0, 21, 0.3);
}

.c-dark-theme .custom-file-label {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-color: rgba(0, 0, 21, 0.3);
}

.c-dark-theme .custom-file-label:after {
  color: #e1e1e1;
  background-color: #181924;
}

.c-dark-theme .input-group-text {
  color: hsla(0, 0%, 88.2%, 0.5);
  background-color: #181924;
  border-color: #181924;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e55353 !important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e55353'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23e55353' stroke='none'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #2eb85c !important;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%232eb85c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.c-dark-theme .border {
  border: 1px solid #181924 !important;
}

.c-dark-theme .jumbotron {
  background-color: hsla(0, 0%, 100%, 0.1);
}

.c-dark-theme .btn-primary {
  color: #fff;
  background-color: #4638c2;
  border-color: #4638c2;
}

.c-dark-theme .list-group-item {
  color: #e1e1e1;
  background-color: inherit;
  border-color: #181924;
}

.c-dark-theme .list-group-item.active {
  color: #fff;
  background-color: #4638c2;
  border-color: #4638c2;
}

.c-dark-theme .list-group-item.disabled,
.c-dark-theme .list-group-item:disabled {
  color: hsla(0, 0%, 88.2%, 0.5);
  background-color: rgba(24, 25, 36, 0.8);
}

.c-dark-theme .list-group-item-primary {
  color: #241d6f;
  background-color: #cbc7ee;
}
.c-dark-theme .list-group-item-secondary {
  color: #282936;
  background-color: #cdcecf;
}
.c-dark-theme .list-group-item-success {
  color: #24543e;
  background-color: #cbe5d4;
}
.c-dark-theme .list-group-item-danger {
  color: #6d3540;
  background-color: #f2d4d4;
}
.c-dark-theme .list-group-item-warning {
  color: #755721;
  background-color: #f7e7c4;
}
.c-dark-theme .list-group-item-info {
  color: #255084;
  background-color: #cce2f9;
}
.c-dark-theme .list-group-item-light {
  color: #101120;
  background-color: #c0c1c3;
}
.c-dark-theme .list-group-item-dark {
  color: #0c0d1d;
  background-color: #bebfc2;
}
.c-dark-theme .list-group-item-accent-primary {
  border-left: 4px solid #4638c2;
}
.c-dark-theme .list-group-item-accent-secondary {
  border-left: 4px solid #4c4f54;
}
.c-dark-theme .list-group-item-accent-success {
  border-left: 4px solid #45a164;
}
.c-dark-theme .list-group-item-accent-danger {
  border-left: 4px solid #d16767;
}
.c-dark-theme .list-group-item-accent-warning {
  border-left: 4px solid #e1a82d;
}
.c-dark-theme .list-group-item-accent-info {
  border-left: 4px solid #4799eb;
}
.c-dark-theme .list-group-item-accent-light {
  border-left: 4px solid #282933;
}
.c-dark-theme .list-group-item-accent-dark {
  border-left: 4px solid #181924;
}
.c-dark-theme .list-group-item-action:focus,
.c-dark-theme .list-group-item-action:hover {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .list-group-item.active {
  color: #fff;
  background-color: #4638c2;
  border-color: #4638c2;
}
.c-dark-theme .list-group-item-action {
  color: #e1e1e1;
}
.c-dark-theme .nav-tabs .nav-item.show .nav-link,
.c-dark-theme .nav-tabs .nav-link.active {
  color: #e1e1e1;
  background-color: #24252f;
  border-color: #24252f;
}
.c-dark-theme .nav-tabs .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.5);
}
.c-dark-theme .nav-tabs {
  border-color: #24252f;
}
.c-dark-theme .nav-tabs .nav-link:focus,
.c-dark-theme .nav-tabs .nav-link:hover {
  border-color: #24252f;
}

.c-dark-theme .page-link {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.075);
  border-color: #181924;
}
.c-dark-theme .page-item.active .page-link {
  color: #fff;
  background-color: #4638c2;
  border-color: #321fdb;
}
.c-dark-theme .page-item.disabled .page-link {
  color: hsla(0, 0%, 100%, 0.5);
  background-color: hsla(0, 0%, 100%, 0.025);
  border-color: #181924;
}
.c-dark-theme .page-link:hover {
  color: #e1e1e1;
  background-color: #181924;
  border-color: #181924;
}
.c-dark-theme .tippy-box[data-theme~='cpopover'] {
  background-color: #1e1e29;
  border-color: #181924;
}
.c-dark-theme .popover-header {
  color: #e1e1e1;
  background-color: #181924;
  border-bottom-color: #0e0e15;
}
.c-dark-theme .popover-body {
  color: #e1e1e1;
}

.c-dark-theme .tippy-box[data-theme~='cpopover'] {
  background-color: #1e1e29;
  border-color: #181924;
}

.c-dark-theme .tippy-box[data-theme~='cpopover'][data-placement^='top'] > .tippy-arrow:before {
  border-top-color: #181924;
}
.c-dark-theme .tippy-box[data-theme~='cpopover'][data-placement^='top'] > .tippy-arrow:after {
  border-top-color: #1e1e29;
}
.c-dark-theme .tippy-box[data-theme~='cpopover'][data-placement^='bottom'] > .tippy-arrow:before {
  border-bottom-color: #181924;
}
.c-dark-theme .tippy-box[data-theme~='cpopover'][data-placement^='bottom'] > .tippy-arrow:after {
  border-bottom-color: #1e1e29;
}
.c-dark-theme .tippy-box[data-theme~='cpopover'][data-placement^='left'] > .tippy-arrow:before {
  border-left-color: #181924;
}
.c-dark-theme .tippy-box[data-theme~='cpopover'][data-placement^='left'] > .tippy-arrow:after {
  border-left-color: #1e1e29;
}
.c-dark-theme .tippy-box[data-theme~='cpopover'][data-placement^='right'] > .tippy-arrow:before {
  border-right-color: #181924;
}
.c-dark-theme .tippy-box[data-theme~='cpopover'][data-placement^='right'] > .tippy-arrow:after {
  border-right-color: #1e1e29;
}

.c-dark-theme .badge-primary {
  color: #fff;
  background-color: #4638c2;
}
.c-dark-theme a.badge-primary:focus,
.c-dark-theme a.badge-primary:hover {
  color: #fff;
  background-color: #382d9a;
}
.c-dark-theme a.badge-primary.focus,
.c-dark-theme a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(70, 56, 194, 0.5);
}
.c-dark-theme .badge-secondary {
  color: #fff;
  background-color: #4c4f54;
}
.c-dark-theme a.badge-secondary:focus,
.c-dark-theme a.badge-secondary:hover {
  color: #fff;
  background-color: #343639;
}
.c-dark-theme a.badge-secondary.focus,
.c-dark-theme a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(76, 79, 84, 0.5);
}
.c-dark-theme .badge-success {
  color: #fff;
  background-color: #45a164;
}
.c-dark-theme a.badge-success:focus,
.c-dark-theme a.badge-success:hover {
  color: #fff;
  background-color: #367d4e;
}
.c-dark-theme a.badge-success.focus,
.c-dark-theme a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(69, 161, 100, 0.5);
}
.c-dark-theme .badge-info {
  color: #fff;
  background-color: #4799eb;
}
.c-dark-theme a.badge-info:focus,
.c-dark-theme a.badge-info:hover {
  color: #fff;
  background-color: #1a80e6;
}
.c-dark-theme a.badge-info.focus,
.c-dark-theme a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(71, 153, 235, 0.5);
}
.c-dark-theme .badge-warning {
  color: #4f5d73;
  background-color: #e1a82d;
}
.c-dark-theme a.badge-warning:focus,
.c-dark-theme a.badge-warning:hover {
  color: #4f5d73;
  background-color: #c08c1b;
}
.c-dark-theme a.badge-warning.focus,
.c-dark-theme a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(225, 168, 45, 0.5);
}
.c-dark-theme .badge-danger {
  color: #fff;
  background-color: #d16767;
}
.c-dark-theme a.badge-danger:focus,
.c-dark-theme a.badge-danger:hover {
  color: #fff;
  background-color: #c54040;
}
.c-dark-theme a.badge-danger.focus,
.c-dark-theme a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(209, 103, 103, 0.5);
}
.c-dark-theme .badge-light {
  color: #fff;
  background-color: #282933;
}
.c-dark-theme a.badge-light:focus,
.c-dark-theme a.badge-light:hover {
  color: #fff;
  background-color: #0a0a0d;
}
.c-dark-theme a.badge-light.focus,
.c-dark-theme a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 32, 42, 0.5);
}
.c-dark-theme .badge-dark {
  color: #fff;
  background-color: #181924;
}
.c-dark-theme a.badge-dark:focus,
.c-dark-theme a.badge-dark:hover {
  color: #fff;
  background-color: #040405;
}
.c-dark-theme a.badge-dark.focus,
.c-dark-theme a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(24, 25, 36, 0.5);
}
.c-dark-theme .modal-content {
  background-color: #2a2b36;
  border-color: #2a2b36;
}
.c-dark-theme .modal-header {
  border-color: #2a2b36;
}
.modal-header {
  color: #000;
}
.c-dark-theme .close {
  color: #fff;
  text-shadow: 0 1px 0 #000015;
  transition: opacity 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: none;
}
@media not all, (hover: hover) {
  .c-dark-theme .close:hover {
    color: #fff;
  }
}

.c-dark-theme .modal-footer {
  border-color: rgba(0, 0, 0, 0.2);
}
.c-dark-theme .bg-gradient-primary {
  background: #2d2587 !important;
  background: linear-gradient(45deg, #4638c2, #2d2587) !important;
  border-color: #2d2587 !important;
}
.c-dark-theme .bg-gradient-secondary {
  background: #fff !important;
  background: linear-gradient(45deg, #d1d2d3, #fff) !important;
  border-color: #fff !important;
}
.c-dark-theme .bg-gradient-success {
  background: #2e8c47 !important;
  background: linear-gradient(45deg, #45a164, #2e8c47) !important;
  border-color: #2e8c47 !important;
}
.c-dark-theme .bg-gradient-info {
  background: #4280b4 !important;
  background: linear-gradient(45deg, #4799eb, #4280b4) !important;
  border-color: #4280b4 !important;
}
.c-dark-theme .bg-gradient-warning {
  background: #dd9124 !important;
  background: linear-gradient(45deg, #e1a82d, #dd9124) !important;
  border-color: #dd9124 !important;
}
.c-dark-theme .bg-gradient-danger {
  background: #c14f4f !important;
  background: linear-gradient(45deg, #d16767, #c14f4f) !important;
  border-color: #c14f4f !important;
}
.c-dark-theme .bg-gradient-light {
  background: #fff !important;
  background: linear-gradient(45deg, #e8e8e8, #fff) !important;
  border-color: #fff !important;
}
.c-dark-theme .bg-gradient-dark {
  background: #292a2b !important;
  background: linear-gradient(45deg, #4c4f54, #292a2b) !important;
  border-color: #292a2b !important;
}

.panel-heading-btn > .btn i {
  height: unset;
  margin: 0;
}

.modal-backdrop {
  background-color: #000005 !important;
}

.c-dark-theme .c-switch-input {
  outline: none;
}
.c-dark-theme .c-switch-slider {
  outline: none;
  background-color: hsla(0, 0%, 100%, 0.15);
  border-color: hsla(0, 0%, 100%, 0.2);
}

.c-dark-theme .c-switch-primary .c-switch-input:checked + .c-switch-slider {
  background-color: #4638c2;
  border-color: #382d9a;
}
.c-dark-theme .c-switch-primary .c-switch-input:checked + .c-switch-slider:before {
  border-color: #382d9a;
}
.c-dark-theme .c-switch-3d-primary .c-switch-input:checked + .c-switch-slider {
  background-color: #4638c2;
}
.c-dark-theme .c-switch-outline-primary .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #4638c2;
}
.c-dark-theme .c-switch-outline-primary .c-switch-input:checked + .c-switch-slider:before {
  border-color: #4638c2;
}
.c-dark-theme .c-switch-outline-primary .c-switch-input:checked + .c-switch-slider:after {
  color: #4638c2;
}
.c-dark-theme .c-switch-opposite-primary .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #4638c2;
}
.c-dark-theme .c-switch-opposite-primary .c-switch-input:checked + .c-switch-slider:before {
  background-color: #4638c2;
  border-color: #4638c2;
}
.c-dark-theme .c-switch-opposite-primary .c-switch-input:checked + .c-switch-slider:after {
  color: #4638c2;
}
.c-dark-theme .c-switch-secondary .c-switch-input:checked + .c-switch-slider {
  background-color: #4c4f54;
  border-color: #343639;
}
.c-dark-theme .c-switch-secondary .c-switch-input:checked + .c-switch-slider:before {
  border-color: #343639;
}
.c-dark-theme .c-switch-3d-secondary .c-switch-input:checked + .c-switch-slider {
  background-color: #4c4f54;
}
.c-dark-theme .c-switch-outline-secondary .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #4c4f54;
}
.c-dark-theme .c-switch-outline-secondary .c-switch-input:checked + .c-switch-slider:before {
  border-color: #4c4f54;
}
.c-dark-theme .c-switch-outline-secondary .c-switch-input:checked + .c-switch-slider:after {
  color: #4c4f54;
}
.c-dark-theme .c-switch-opposite-secondary .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #4c4f54;
}
.c-dark-theme .c-switch-opposite-secondary .c-switch-input:checked + .c-switch-slider:before {
  background-color: #4c4f54;
  border-color: #4c4f54;
}
.c-dark-theme .c-switch-opposite-secondary .c-switch-input:checked + .c-switch-slider:after {
  color: #4c4f54;
}
.c-dark-theme .c-switch-success .c-switch-input:checked + .c-switch-slider {
  background-color: #45a164;
  border-color: #367d4e;
}
.c-dark-theme .c-switch-success .c-switch-input:checked + .c-switch-slider:before {
  border-color: #367d4e;
}
.c-dark-theme .c-switch-3d-success .c-switch-input:checked + .c-switch-slider {
  background-color: #45a164;
}
.c-dark-theme .c-switch-outline-success .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #45a164;
}
.c-dark-theme .c-switch-outline-success .c-switch-input:checked + .c-switch-slider:before {
  border-color: #45a164;
}
.c-dark-theme .c-switch-outline-success .c-switch-input:checked + .c-switch-slider:after {
  color: #45a164;
}
.c-dark-theme .c-switch-opposite-success .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #45a164;
}
.c-dark-theme .c-switch-opposite-success .c-switch-input:checked + .c-switch-slider:before {
  background-color: #45a164;
  border-color: #45a164;
}
.c-dark-theme .c-switch-opposite-success .c-switch-input:checked + .c-switch-slider:after {
  color: #45a164;
}
.c-dark-theme .c-switch-info .c-switch-input:checked + .c-switch-slider {
  background-color: #4799eb;
  border-color: #1a80e6;
}
.c-dark-theme .c-switch-info .c-switch-input:checked + .c-switch-slider:before {
  border-color: #1a80e6;
}
.c-dark-theme .c-switch-3d-info .c-switch-input:checked + .c-switch-slider {
  background-color: #4799eb;
}
.c-dark-theme .c-switch-outline-info .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #4799eb;
}
.c-dark-theme .c-switch-outline-info .c-switch-input:checked + .c-switch-slider:before {
  border-color: #4799eb;
}
.c-dark-theme .c-switch-outline-info .c-switch-input:checked + .c-switch-slider:after {
  color: #4799eb;
}
.c-dark-theme .c-switch-opposite-info .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #4799eb;
}
.c-dark-theme .c-switch-opposite-info .c-switch-input:checked + .c-switch-slider:before {
  background-color: #4799eb;
  border-color: #4799eb;
}
.c-dark-theme .c-switch-opposite-info .c-switch-input:checked + .c-switch-slider:after {
  color: #4799eb;
}
.c-dark-theme .c-switch-warning .c-switch-input:checked + .c-switch-slider {
  background-color: #e1a82d;
  border-color: #c08c1b;
}
.c-dark-theme .c-switch-warning .c-switch-input:checked + .c-switch-slider:before {
  border-color: #c08c1b;
}
.c-dark-theme .c-switch-3d-warning .c-switch-input:checked + .c-switch-slider {
  background-color: #e1a82d;
}
.c-dark-theme .c-switch-outline-warning .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #e1a82d;
}
.c-dark-theme .c-switch-outline-warning .c-switch-input:checked + .c-switch-slider:before {
  border-color: #e1a82d;
}
.c-dark-theme .c-switch-outline-warning .c-switch-input:checked + .c-switch-slider:after {
  color: #e1a82d;
}
.c-dark-theme .c-switch-opposite-warning .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #e1a82d;
}
.c-dark-theme .c-switch-opposite-warning .c-switch-input:checked + .c-switch-slider:before {
  background-color: #e1a82d;
  border-color: #e1a82d;
}
.c-dark-theme .c-switch-opposite-warning .c-switch-input:checked + .c-switch-slider:after {
  color: #e1a82d;
}
.c-dark-theme .c-switch-danger .c-switch-input:checked + .c-switch-slider {
  background-color: #d16767;
  border-color: #c54040;
}
.c-dark-theme .c-switch-danger .c-switch-input:checked + .c-switch-slider:before {
  border-color: #c54040;
}
.c-dark-theme .c-switch-3d-danger .c-switch-input:checked + .c-switch-slider {
  background-color: #d16767;
}
.c-dark-theme .c-switch-outline-danger .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #d16767;
}
.c-dark-theme .c-switch-outline-danger .c-switch-input:checked + .c-switch-slider:before {
  border-color: #d16767;
}
.c-dark-theme .c-switch-outline-danger .c-switch-input:checked + .c-switch-slider:after {
  color: #d16767;
}
.c-dark-theme .c-switch-opposite-danger .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #d16767;
}
.c-dark-theme .c-switch-opposite-danger .c-switch-input:checked + .c-switch-slider:before {
  background-color: #d16767;
  border-color: #d16767;
}
.c-dark-theme .c-switch-opposite-danger .c-switch-input:checked + .c-switch-slider:after {
  color: #d16767;
}
.c-dark-theme .c-switch-light .c-switch-input:checked + .c-switch-slider {
  background-color: #282933;
  border-color: #0a0a0d;
}
.c-dark-theme .c-switch-light .c-switch-input:checked + .c-switch-slider:before {
  border-color: #0a0a0d;
}
.c-dark-theme .c-switch-3d-light .c-switch-input:checked + .c-switch-slider {
  background-color: #282933;
}
.c-dark-theme .c-switch-outline-light .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #282933;
}
.c-dark-theme .c-switch-outline-light .c-switch-input:checked + .c-switch-slider:before {
  border-color: #282933;
}
.c-dark-theme .c-switch-outline-light .c-switch-input:checked + .c-switch-slider:after {
  color: #282933;
}
.c-dark-theme .c-switch-opposite-light .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #282933;
}
.c-dark-theme .c-switch-opposite-light .c-switch-input:checked + .c-switch-slider:before {
  background-color: #282933;
  border-color: #282933;
}
.c-dark-theme .c-switch-opposite-light .c-switch-input:checked + .c-switch-slider:after {
  color: #282933;
}
.c-dark-theme .c-switch-dark .c-switch-input:checked + .c-switch-slider {
  background-color: #181924;
  border-color: #040405;
}
.c-dark-theme .c-switch-dark .c-switch-input:checked + .c-switch-slider:before {
  border-color: #040405;
}
.c-dark-theme .c-switch-3d-dark .c-switch-input:checked + .c-switch-slider {
  background-color: #181924;
}
.c-dark-theme .c-switch-outline-dark .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #181924;
}
.c-dark-theme .c-switch-outline-dark .c-switch-input:checked + .c-switch-slider:before {
  border-color: #181924;
}
.c-dark-theme .c-switch-outline-dark .c-switch-input:checked + .c-switch-slider:after {
  color: #181924;
}
.c-dark-theme .c-switch-opposite-dark .c-switch-input:checked + .c-switch-slider {
  background-color: #fff;
  border-color: #181924;
}
.c-dark-theme .c-switch-opposite-dark .c-switch-input:checked + .c-switch-slider:before {
  background-color: #181924;
  border-color: #181924;
}
.c-dark-theme .c-switch-opposite-dark .c-switch-input:checked + .c-switch-slider:after {
  color: #181924;
}

.c-dark-theme .alert-primary {
  color: #241d6f;
  background-color: #dad7f3;
  border-color: #cbc7ee;
}
.c-dark-theme .alert-primary hr {
  border-top-color: #b9b3e8;
}
.c-dark-theme .alert-primary .alert-link {
  color: #171247;
}
.c-dark-theme .alert-secondary {
  color: #282936;
  background-color: #dbdcdd;
  border-color: #cdcecf;
}
.c-dark-theme .alert-secondary hr {
  border-top-color: #c0c1c3;
}
.c-dark-theme .alert-secondary .alert-link {
  color: #121319;
}
.c-dark-theme .alert-success {
  color: #24543e;
  background-color: #daece0;
  border-color: #cbe5d4;
}
.c-dark-theme .alert-success hr {
  border-top-color: #baddc6;
}
.c-dark-theme .alert-success .alert-link {
  color: #153024;
}
.c-dark-theme .alert-info {
  color: #255084;
  background-color: #daebfb;
  border-color: #cce2f9;
}
.c-dark-theme .alert-info hr {
  border-top-color: #b5d5f6;
}
.c-dark-theme .alert-info .alert-link {
  color: #1a385c;
}
.c-dark-theme .alert-warning {
  color: #755721;
  background-color: #f9eed5;
  border-color: #f7e7c4;
}
.c-dark-theme .alert-warning hr {
  border-top-color: #f4deae;
}
.c-dark-theme .alert-warning .alert-link {
  color: #4d3916;
}
.c-dark-theme .alert-danger {
  color: #6d3540;
  background-color: #f6e1e1;
  border-color: #f2d4d4;
}
.c-dark-theme .alert-danger hr {
  border-top-color: #ecc0c0;
}
.c-dark-theme .alert-danger .alert-link {
  color: #4b242c;
}
.c-dark-theme .alert-light {
  color: #101120;
  background-color: #d2d2d4;
  border-color: #c0c1c3;
}
.c-dark-theme .alert-light hr {
  border-top-color: #b3b4b7;
}
.c-dark-theme .alert-light .alert-link {
  color: #000;
}
.c-dark-theme .alert-dark {
  color: #0c0d1d;
  background-color: #d1d1d3;
  border-color: #bebfc2;
}
.c-dark-theme .alert-dark hr {
  border-top-color: #b1b2b6;
}
.c-dark-theme .alert-dark .alert-link {
  color: #000;
}

.c-dark-theme .btn-primary {
  color: #fff;
  background-color: #4638c2;
  border-color: #4638c2;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-primary:hover {
    color: #fff;
    background-color: #3b2fa4;
    border-color: #382d9a;
  }
}
.c-dark-theme .btn-primary.focus,
.c-dark-theme .btn-primary:focus {
  color: #fff;
  background-color: #3b2fa4;
  border-color: #382d9a;
  box-shadow: 0 0 0 0.2rem rgba(98, 86, 203, 0.5);
}
.c-dark-theme .btn-primary.disabled,
.c-dark-theme .btn-primary:disabled {
  color: #fff;
  background-color: #4638c2;
  border-color: #4638c2;
}
.c-dark-theme .btn-primary:not(:disabled):not(.disabled):active,
.show > .c-dark-theme .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #382d9a;
  border-color: #342a91;
}
.c-dark-theme .btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .c-dark-theme .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 86, 203, 0.5);
}
.c-dark-theme .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #382d9a;
  border-color: #342a91;
}
.c-dark-theme .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(98, 86, 203, 0.5);
}
.c-dark-theme .btn-secondary {
  color: #fff;
  background-color: #4c4f54;
  border-color: #4c4f54;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-secondary:hover {
    color: #fff;
    background-color: #3a3c40;
    border-color: #343639;
  }
}
.c-dark-theme .btn-secondary.focus,
.c-dark-theme .btn-secondary:focus {
  color: #fff;
  background-color: #3a3c40;
  border-color: #343639;
  box-shadow: 0 0 0 0.2rem rgba(103, 105, 110, 0.5);
}
.c-dark-theme .btn-secondary.disabled,
.c-dark-theme .btn-secondary:disabled {
  color: #fff;
  background-color: #4c4f54;
  border-color: #4c4f54;
}
.c-dark-theme .btn-secondary:not(:disabled):not(.disabled):active,
.show > .c-dark-theme .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #343639;
  border-color: #2e3033;
}
.c-dark-theme .btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .c-dark-theme .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 105, 110, 0.5);
}
.c-dark-theme .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #343639;
  border-color: #2e3033;
}
.c-dark-theme .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 105, 110, 0.5);
}
.c-dark-theme .btn-success {
  color: #fff;
  background-color: #45a164;
  border-color: #45a164;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-success:hover {
    color: #fff;
    background-color: #3a8653;
    border-color: #367d4e;
  }
}
.c-dark-theme .btn-success.focus,
.c-dark-theme .btn-success:focus {
  color: #fff;
  background-color: #3a8653;
  border-color: #367d4e;
  box-shadow: 0 0 0 0.2rem rgba(97, 175, 123, 0.5);
}
.c-dark-theme .btn-success.disabled,
.c-dark-theme .btn-success:disabled {
  color: #fff;
  background-color: #45a164;
  border-color: #45a164;
}
.c-dark-theme .btn-success:not(:disabled):not(.disabled):active,
.show > .c-dark-theme .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #367d4e;
  border-color: #327448;
}
.c-dark-theme .btn-success:not(:disabled):not(.disabled):active:focus,
.show > .c-dark-theme .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 175, 123, 0.5);
}
.c-dark-theme .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #367d4e;
  border-color: #327448;
}
.c-dark-theme .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(97, 175, 123, 0.5);
}
.c-dark-theme .btn-info {
  color: #fff;
  background-color: #4799eb;
  border-color: #4799eb;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-info:hover {
    color: #fff;
    background-color: #2586e7;
    border-color: #1a80e6;
  }
}
.c-dark-theme .btn-info.focus,
.c-dark-theme .btn-info:focus {
  color: #fff;
  background-color: #2586e7;
  border-color: #1a80e6;
  box-shadow: 0 0 0 0.2rem rgba(99, 168, 238, 0.5);
}
.c-dark-theme .btn-info.disabled,
.c-dark-theme .btn-info:disabled {
  color: #fff;
  background-color: #4799eb;
  border-color: #4799eb;
}
.c-dark-theme .btn-info:not(:disabled):not(.disabled):active,
.show > .c-dark-theme .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1a80e6;
  border-color: #1879da;
}
.c-dark-theme .btn-info:not(:disabled):not(.disabled):active:focus,
.show > .c-dark-theme .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 168, 238, 0.5);
}
.c-dark-theme .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1a80e6;
  border-color: #1879da;
}
.c-dark-theme .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(99, 168, 238, 0.5);
}
.c-dark-theme .btn-warning {
  color: #4f5d73;
  background-color: #e1a82d;
  border-color: #e1a82d;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-warning:hover {
    color: #4f5d73;
    background-color: #cb941d;
    border-color: #c08c1b;
  }
}
.c-dark-theme .btn-warning.focus,
.c-dark-theme .btn-warning:focus {
  color: #4f5d73;
  background-color: #cb941d;
  border-color: #c08c1b;
  box-shadow: 0 0 0 0.2rem rgba(203, 157, 56, 0.5);
}
.c-dark-theme .btn-warning.disabled,
.c-dark-theme .btn-warning:disabled {
  color: #4f5d73;
  background-color: #e1a82d;
  border-color: #e1a82d;
}
.c-dark-theme .btn-warning:not(:disabled):not(.disabled):active,
.show > .c-dark-theme .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #c08c1b;
  border-color: #b4841a;
}
.c-dark-theme .btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .c-dark-theme .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 157, 56, 0.5);
}
.c-dark-theme .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #c08c1b;
  border-color: #b4841a;
}
.c-dark-theme .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 157, 56, 0.5);
}
.c-dark-theme .btn-danger {
  color: #fff;
  background-color: #d16767;
  border-color: #d16767;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-danger:hover {
    color: #fff;
    background-color: #c84949;
    border-color: #c54040;
  }
}
.c-dark-theme .btn-danger.focus,
.c-dark-theme .btn-danger:focus {
  color: #fff;
  background-color: #c84949;
  border-color: #c54040;
  box-shadow: 0 0 0 0.2rem rgba(216, 126, 126, 0.5);
}
.c-dark-theme .btn-danger.disabled,
.c-dark-theme .btn-danger:disabled {
  color: #fff;
  background-color: #d16767;
  border-color: #d16767;
}
.c-dark-theme .btn-danger:not(:disabled):not(.disabled):active,
.show > .c-dark-theme .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c54040;
  border-color: #bf3939;
}
.c-dark-theme .btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .c-dark-theme .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 126, 126, 0.5);
}
.c-dark-theme .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c54040;
  border-color: #bf3939;
}
.c-dark-theme .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 126, 126, 0.5);
}
.c-dark-theme .btn-light {
  color: #fff;
  background-color: #282933;
  border-color: #282933;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-light:hover {
    color: #fff;
    background-color: #0f1014;
    border-color: #0a0a0d;
  }
}
.c-dark-theme .btn-light.focus,
.c-dark-theme .btn-light:focus {
  color: #fff;
  background-color: #0f1014;
  border-color: #0a0a0d;
  box-shadow: 0 0 0 0.2rem rgba(65, 66, 74, 0.5);
}
.c-dark-theme .btn-light.disabled,
.c-dark-theme .btn-light:disabled {
  color: #fff;
  background-color: #282933;
  border-color: #282933;
}
.c-dark-theme .btn-light:not(:disabled):not(.disabled):active,
.show > .c-dark-theme .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #0a0a0d;
  border-color: #040406;
}
.c-dark-theme .btn-light:not(:disabled):not(.disabled):active:focus,
.show > .c-dark-theme .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 66, 74, 0.5);
}
.c-dark-theme .show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #0a0a0d;
  border-color: #040406;
}
.c-dark-theme .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(65, 66, 74, 0.5);
}
.c-dark-theme .btn-dark {
  color: #fff;
  background-color: #181924;
  border-color: #181924;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-dark:hover {
    color: #fff;
    background-color: #09090d;
    border-color: #040405;
  }
}
.c-dark-theme .btn-dark.focus,
.c-dark-theme .btn-dark:focus {
  color: #fff;
  background-color: #09090d;
  border-color: #040405;
  box-shadow: 0 0 0 0.2rem rgba(59, 59, 69, 0.5);
}
.c-dark-theme .btn-dark.disabled,
.c-dark-theme .btn-dark:disabled {
  color: #fff;
  background-color: #181924;
  border-color: #181924;
}
.c-dark-theme .btn-dark:not(:disabled):not(.disabled):active,
.show > .c-dark-theme .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #040405;
  border-color: #000;
}
.c-dark-theme .btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .c-dark-theme .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 59, 69, 0.5);
}
.c-dark-theme .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #040405;
  border-color: #000;
}
.c-dark-theme .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(59, 59, 69, 0.5);
}

.c-dark-theme .btn-outline-primary {
  color: #4638c2;
  border-color: #4638c2;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-outline-primary:hover {
    color: #fff;
    background-color: #4638c2;
    border-color: #4638c2;
  }
}
.c-dark-theme .btn-outline-primary.focus,
.c-dark-theme .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 56, 194, 0.5);
}
.c-dark-theme .btn-outline-primary.disabled,
.c-dark-theme .btn-outline-primary:disabled {
  color: #4638c2;
  background-color: transparent;
}
.c-dark-theme .btn-outline-primary:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4638c2;
  border-color: #4638c2;
}
.c-dark-theme .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 56, 194, 0.5);
}
.c-dark-theme .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4638c2;
  border-color: #4638c2;
}
.c-dark-theme .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 56, 194, 0.5);
}
.c-dark-theme .btn-outline-secondary {
  color: #4c4f54;
  border-color: #4c4f54;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-outline-secondary:hover {
    color: #fff;
    background-color: #4c4f54;
    border-color: #4c4f54;
  }
}
.c-dark-theme .btn-outline-secondary.focus,
.c-dark-theme .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 79, 84, 0.5);
}
.c-dark-theme .btn-outline-secondary.disabled,
.c-dark-theme .btn-outline-secondary:disabled {
  color: #4c4f54;
  background-color: transparent;
}
.c-dark-theme .btn-outline-secondary:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-outline-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4c4f54;
  border-color: #4c4f54;
}
.c-dark-theme .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 79, 84, 0.5);
}
.c-dark-theme .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4c4f54;
  border-color: #4c4f54;
}
.c-dark-theme .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 79, 84, 0.5);
}
.c-dark-theme .btn-outline-success {
  color: #45a164;
  border-color: #45a164;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-outline-success:hover {
    color: #fff;
    background-color: #45a164;
    border-color: #45a164;
  }
}
.c-dark-theme .btn-outline-success.focus,
.c-dark-theme .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 161, 100, 0.5);
}
.c-dark-theme .btn-outline-success.disabled,
.c-dark-theme .btn-outline-success:disabled {
  color: #45a164;
  background-color: transparent;
}
.c-dark-theme .btn-outline-success:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-outline-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #45a164;
  border-color: #45a164;
}
.c-dark-theme .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-outline-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 161, 100, 0.5);
}
.c-dark-theme .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #45a164;
  border-color: #45a164;
}
.c-dark-theme .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 161, 100, 0.5);
}
.c-dark-theme .btn-outline-info {
  color: #4799eb;
  border-color: #4799eb;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-outline-info:hover {
    color: #fff;
    background-color: #4799eb;
    border-color: #4799eb;
  }
}
.c-dark-theme .btn-outline-info.focus,
.c-dark-theme .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 153, 235, 0.5);
}
.c-dark-theme .btn-outline-info.disabled,
.c-dark-theme .btn-outline-info:disabled {
  color: #4799eb;
  background-color: transparent;
}
.c-dark-theme .btn-outline-info:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-outline-info:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4799eb;
  border-color: #4799eb;
}
.c-dark-theme .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-outline-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 153, 235, 0.5);
}
.c-dark-theme .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #4799eb;
  border-color: #4799eb;
}
.c-dark-theme .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 153, 235, 0.5);
}
.c-dark-theme .btn-outline-warning {
  color: #e1a82d;
  border-color: #e1a82d;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-outline-warning:hover {
    color: #4f5d73;
    background-color: #e1a82d;
    border-color: #e1a82d;
  }
}
.c-dark-theme .btn-outline-warning.focus,
.c-dark-theme .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 168, 45, 0.5);
}
.c-dark-theme .btn-outline-warning.disabled,
.c-dark-theme .btn-outline-warning:disabled {
  color: #e1a82d;
  background-color: transparent;
}
.c-dark-theme .btn-outline-warning:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-outline-warning:not(:disabled):not(.disabled):active {
  color: #4f5d73;
  background-color: #e1a82d;
  border-color: #e1a82d;
}
.c-dark-theme .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-outline-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 168, 45, 0.5);
}
.c-dark-theme .show > .btn-outline-warning.dropdown-toggle {
  color: #4f5d73;
  background-color: #e1a82d;
  border-color: #e1a82d;
}
.c-dark-theme .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 168, 45, 0.5);
}
.c-dark-theme .btn-outline-danger {
  color: #d16767;
  border-color: #d16767;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-outline-danger:hover {
    color: #fff;
    background-color: #d16767;
    border-color: #d16767;
  }
}
.c-dark-theme .btn-outline-danger.focus,
.c-dark-theme .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 103, 103, 0.5);
}
.c-dark-theme .btn-outline-danger.disabled,
.c-dark-theme .btn-outline-danger:disabled {
  color: #d16767;
  background-color: transparent;
}
.c-dark-theme .btn-outline-danger:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-outline-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #d16767;
  border-color: #d16767;
}
.c-dark-theme .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-outline-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 103, 103, 0.5);
}
.c-dark-theme .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d16767;
  border-color: #d16767;
}
.c-dark-theme .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 103, 103, 0.5);
}
.c-dark-theme .btn-outline-light {
  color: #282933;
  border-color: #282933;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-outline-light:hover {
    color: #fff;
    background-color: #282933;
    border-color: #282933;
  }
}
.c-dark-theme .btn-outline-light.focus,
.c-dark-theme .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 32, 42, 0.5);
}
.c-dark-theme .btn-outline-light.disabled,
.c-dark-theme .btn-outline-light:disabled {
  color: #282933;
  background-color: transparent;
}
.c-dark-theme .btn-outline-light:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-outline-light:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #282933;
  border-color: #282933;
}
.c-dark-theme .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-outline-light:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 32, 42, 0.5);
}
.c-dark-theme .show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #282933;
  border-color: #282933;
}
.c-dark-theme .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 32, 42, 0.5);
}
.c-dark-theme .btn-outline-dark {
  color: #181924;
  border-color: #181924;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .btn-outline-dark:hover {
    color: #fff;
    background-color: #181924;
    border-color: #181924;
  }
}
.c-dark-theme .btn-outline-dark.focus,
.c-dark-theme .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 25, 36, 0.5);
}
.c-dark-theme .btn-outline-dark.disabled,
.c-dark-theme .btn-outline-dark:disabled {
  color: #181924;
  background-color: transparent;
}
.c-dark-theme .btn-outline-dark:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-outline-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #181924;
  border-color: #181924;
}
.c-dark-theme .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-outline-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 25, 36, 0.5);
}
.c-dark-theme .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #181924;
  border-color: #181924;
}
.c-dark-theme .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 25, 36, 0.5);
}

.c-dark-theme .btn-ghost-primary {
  color: #4638c2;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-primary:hover {
  color: #fff;
  background-color: #4638c2;
  border-color: #4638c2;
}
.c-dark-theme .btn-ghost-primary.focus,
.c-dark-theme .btn-ghost-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 56, 194, 0.5);
}
.c-dark-theme .btn-ghost-primary.disabled,
.c-dark-theme .btn-ghost-primary:disabled {
  color: #4638c2;
  background-color: transparent;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-primary:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-ghost-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4638c2;
  border-color: #4638c2;
}
.c-dark-theme .btn-ghost-primary:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-ghost-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 56, 194, 0.5);
}
.c-dark-theme .show > .btn-ghost-primary.dropdown-toggle {
  color: #fff;
  background-color: #4638c2;
  border-color: #4638c2;
}
.c-dark-theme .show > .btn-ghost-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(70, 56, 194, 0.5);
}
.c-dark-theme .btn-ghost-secondary {
  color: #4c4f54;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-secondary:hover {
  color: #fff;
  background-color: #4c4f54;
  border-color: #4c4f54;
}
.c-dark-theme .btn-ghost-secondary.focus,
.c-dark-theme .btn-ghost-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 79, 84, 0.5);
}
.c-dark-theme .btn-ghost-secondary.disabled,
.c-dark-theme .btn-ghost-secondary:disabled {
  color: #4c4f54;
  background-color: transparent;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-secondary:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-ghost-secondary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4c4f54;
  border-color: #4c4f54;
}
.c-dark-theme .btn-ghost-secondary:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-ghost-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 79, 84, 0.5);
}
.c-dark-theme .show > .btn-ghost-secondary.dropdown-toggle {
  color: #fff;
  background-color: #4c4f54;
  border-color: #4c4f54;
}
.c-dark-theme .show > .btn-ghost-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 79, 84, 0.5);
}
.c-dark-theme .btn-ghost-success {
  color: #45a164;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-success:hover {
  color: #fff;
  background-color: #45a164;
  border-color: #45a164;
}
.c-dark-theme .btn-ghost-success.focus,
.c-dark-theme .btn-ghost-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 161, 100, 0.5);
}
.c-dark-theme .btn-ghost-success.disabled,
.c-dark-theme .btn-ghost-success:disabled {
  color: #45a164;
  background-color: transparent;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-success:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-ghost-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #45a164;
  border-color: #45a164;
}
.c-dark-theme .btn-ghost-success:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-ghost-success:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 161, 100, 0.5);
}
.c-dark-theme .show > .btn-ghost-success.dropdown-toggle {
  color: #fff;
  background-color: #45a164;
  border-color: #45a164;
}
.c-dark-theme .show > .btn-ghost-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(69, 161, 100, 0.5);
}
.c-dark-theme .btn-ghost-info {
  color: #4799eb;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-info:hover {
  color: #fff;
  background-color: #4799eb;
  border-color: #4799eb;
}
.c-dark-theme .btn-ghost-info.focus,
.c-dark-theme .btn-ghost-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 153, 235, 0.5);
}
.c-dark-theme .btn-ghost-info.disabled,
.c-dark-theme .btn-ghost-info:disabled {
  color: #4799eb;
  background-color: transparent;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-info:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-ghost-info:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #4799eb;
  border-color: #4799eb;
}
.c-dark-theme .btn-ghost-info:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-ghost-info:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 153, 235, 0.5);
}
.c-dark-theme .show > .btn-ghost-info.dropdown-toggle {
  color: #fff;
  background-color: #4799eb;
  border-color: #4799eb;
}
.c-dark-theme .show > .btn-ghost-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 153, 235, 0.5);
}
.c-dark-theme .btn-ghost-warning {
  color: #e1a82d;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-warning:hover {
  color: #4f5d73;
  background-color: #e1a82d;
  border-color: #e1a82d;
}
.c-dark-theme .btn-ghost-warning.focus,
.c-dark-theme .btn-ghost-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 168, 45, 0.5);
}
.c-dark-theme .btn-ghost-warning.disabled,
.c-dark-theme .btn-ghost-warning:disabled {
  color: #e1a82d;
  background-color: transparent;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-warning:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-ghost-warning:not(:disabled):not(.disabled):active {
  color: #4f5d73;
  background-color: #e1a82d;
  border-color: #e1a82d;
}
.c-dark-theme .btn-ghost-warning:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-ghost-warning:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 168, 45, 0.5);
}
.c-dark-theme .show > .btn-ghost-warning.dropdown-toggle {
  color: #4f5d73;
  background-color: #e1a82d;
  border-color: #e1a82d;
}
.c-dark-theme .show > .btn-ghost-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 168, 45, 0.5);
}
.c-dark-theme .btn-ghost-danger {
  color: #d16767;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-danger:hover {
  color: #fff;
  background-color: #d16767;
  border-color: #d16767;
}
.c-dark-theme .btn-ghost-danger.focus,
.c-dark-theme .btn-ghost-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 103, 103, 0.5);
}
.c-dark-theme .btn-ghost-danger.disabled,
.c-dark-theme .btn-ghost-danger:disabled {
  color: #d16767;
  background-color: transparent;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-danger:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-ghost-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #d16767;
  border-color: #d16767;
}
.c-dark-theme .btn-ghost-danger:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-ghost-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 103, 103, 0.5);
}
.c-dark-theme .show > .btn-ghost-danger.dropdown-toggle {
  color: #fff;
  background-color: #d16767;
  border-color: #d16767;
}
.c-dark-theme .show > .btn-ghost-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(209, 103, 103, 0.5);
}
.c-dark-theme .btn-ghost-light {
  color: #282933;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-light:hover {
  color: #fff;
  background-color: #282933;
  border-color: #282933;
}
.c-dark-theme .btn-ghost-light.focus,
.c-dark-theme .btn-ghost-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 32, 42, 0.5);
}
.c-dark-theme .btn-ghost-light.disabled,
.c-dark-theme .btn-ghost-light:disabled {
  color: #282933;
  background-color: transparent;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-light:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-ghost-light:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #282933;
  border-color: #282933;
}
.c-dark-theme .btn-ghost-light:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-ghost-light:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 32, 42, 0.5);
}
.c-dark-theme .show > .btn-ghost-light.dropdown-toggle {
  color: #fff;
  background-color: #282933;
  border-color: #282933;
}
.c-dark-theme .show > .btn-ghost-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(32, 32, 42, 0.5);
}
.c-dark-theme .btn-ghost-dark {
  color: #181924;
  background-color: transparent;
  background-image: none;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-dark:hover {
  color: #fff;
  background-color: #181924;
  border-color: #181924;
}
.c-dark-theme .btn-ghost-dark.focus,
.c-dark-theme .btn-ghost-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 25, 36, 0.5);
}
.c-dark-theme .btn-ghost-dark.disabled,
.c-dark-theme .btn-ghost-dark:disabled {
  color: #181924;
  background-color: transparent;
  border-color: transparent;
}
.c-dark-theme .btn-ghost-dark:not(:disabled):not(.disabled).active,
.c-dark-theme .btn-ghost-dark:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #181924;
  border-color: #181924;
}
.c-dark-theme .btn-ghost-dark:not(:disabled):not(.disabled).active:focus,
.c-dark-theme .btn-ghost-dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 25, 36, 0.5);
}
.c-dark-theme .show > .btn-ghost-dark.dropdown-toggle {
  color: #fff;
  background-color: #181924;
  border-color: #181924;
}
.c-dark-theme .show > .btn-ghost-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(24, 25, 36, 0.5);
}

html:not([dir='rtl']) .c-dark-theme .c-callout-primary {
  border-left-color: #4638c2;
}
[dir='rtl'] .c-dark-theme .c-callout-primary {
  border-right-color: #4638c2;
}
.c-dark-theme .c-callout-primary h4 {
  color: #4638c2;
}
html:not([dir='rtl']) .c-dark-theme .c-callout-secondary {
  border-left-color: #4c4f54;
}
[dir='rtl'] .c-dark-theme .c-callout-secondary {
  border-right-color: #4c4f54;
}
.c-dark-theme .c-callout-secondary h4 {
  color: #4c4f54;
}
html:not([dir='rtl']) .c-dark-theme .c-callout-success {
  border-left-color: #45a164;
}
[dir='rtl'] .c-dark-theme .c-callout-success {
  border-right-color: #45a164;
}
.c-dark-theme .c-callout-success h4 {
  color: #45a164;
}
html:not([dir='rtl']) .c-dark-theme .c-callout-info {
  border-left-color: #4799eb;
}
[dir='rtl'] .c-dark-theme .c-callout-info {
  border-right-color: #4799eb;
}
.c-dark-theme .c-callout-info h4 {
  color: #4799eb;
}
html:not([dir='rtl']) .c-dark-theme .c-callout-warning {
  border-left-color: #e1a82d;
}
[dir='rtl'] .c-dark-theme .c-callout-warning {
  border-right-color: #e1a82d;
}
.c-dark-theme .c-callout-warning h4 {
  color: #e1a82d;
}
html:not([dir='rtl']) .c-dark-theme .c-callout-danger {
  border-left-color: #d16767;
}
[dir='rtl'] .c-dark-theme .c-callout-danger {
  border-right-color: #d16767;
}
.c-dark-theme .c-callout-danger h4 {
  color: #d16767;
}
html:not([dir='rtl']) .c-dark-theme .c-callout-light {
  border-left-color: #282933;
}
[dir='rtl'] .c-dark-theme .c-callout-light {
  border-right-color: #282933;
}
.c-dark-theme .c-callout-light h4 {
  color: #282933;
}
html:not([dir='rtl']) .c-dark-theme .c-callout-dark {
  border-left-color: #181924;
}
[dir='rtl'] .c-dark-theme .c-callout-dark {
  border-right-color: #181924;
}
.c-dark-theme .c-callout-dark h4 {
  color: #181924;
}

.c-dark-theme .card-header-icon-bg {
  border-right: #181924;
}

.c-dark-theme .card-accent-primary {
  border-top: 2px solid #4638c2 !important;
}
.c-dark-theme .card-accent-secondary {
  border-top: 2px solid #4c4f54 !important;
}
.c-dark-theme .card-accent-success {
  border-top: 2px solid #45a164 !important;
}
.c-dark-theme .card-accent-info {
  border-top: 2px solid #4799eb !important;
}
.c-dark-theme .card-accent-warning {
  border-top: 2px solid #e1a82d !important;
}
.c-dark-theme .card-accent-danger {
  border-top: 2px solid #d16767 !important;
}
.c-dark-theme .card-accent-light {
  border-top: 2px solid #282933 !important;
}
.c-dark-theme .card-accent-dark {
  border-top: 2px solid #181924 !important;
}

.c-dark-theme .custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  border-color: #4638c2;
  background-color: #4638c2;
}

.c-dark-theme .custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: hsla(0, 0%, 100%, 0.2);
}

.c-dark-theme .custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  color: #fff;
  background-color: #c4c0ed;
  border-color: #c4c0ed;
}
.c-dark-theme .custom-control-input:disabled ~ .custom-control-label,
.c-dark-theme .custom-control-input[disabled] ~ .custom-control-label {
  color: #8a93a2;
}
.c-dark-theme .custom-control-input:disabled ~ .custom-control-label:before,
.c-dark-theme .custom-control-input[disabled] ~ .custom-control-label:before {
  background-color: hsla(0, 0%, 100%, 0.05);
}
.c-dark-theme .custom-control-label:before {
  background-color: hsla(0, 0%, 100%, 0.1);
  border-color: rgba(0, 0, 21, 0.3);
}
.c-dark-theme .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after,
.c-legacy-theme .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnPjxwYXRoIGZpbGw9JyNmZmYnIGQ9J002LjU2NC43NWwtMy41OSAzLjYxMi0xLjUzOC0xLjU1TDAgNC4yNmwyLjk3NCAyLjk5TDggMi4xOTN6Jy8+PC9zdmc+);
}
.c-dark-theme .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  border-color: #4638c2;
}
.c-dark-theme
  .custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label:before {
  background-color: rgba(70, 56, 194, 0.5);
}
.c-dark-theme
  .custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label:before {
  background-color: rgba(70, 56, 194, 0.5);
}
.c-dark-theme .custom-radio .custom-control-input:checked ~ .custom-control-label:after,
.c-legacy-theme .custom-radio .custom-control-input:checked ~ .custom-control-label:after,
.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgdmlld0JveD0nLTQgLTQgOCA4Jz48Y2lyY2xlIHI9JzMnIGZpbGw9JyNmZmYnLz48L3N2Zz4=);
}
.c-dark-theme .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: rgba(70, 56, 194, 0.5);
}

.c-dark-theme .custom-switch .custom-control-label:after {
  background-color: rgba(0, 0, 21, 0.3);
}
.c-dark-theme .custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: rgba(70, 56, 194, 0.5);
}
.c-dark-theme .custom-select {
  color: #e1e1e1;
  background: hsla(0, 0%, 100%, 0.1)
    url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23636f83' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center/8px 10px;
  border-color: rgba(0, 0, 21, 0.3);
}
.c-dark-theme .custom-select:focus {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.c-dark-theme .custom-select:focus::-ms-value {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .custom-select:disabled {
  color: #8a93a2;
  background-color: hsla(0, 0%, 100%, 0.05);
}
.c-dark-theme .custom-file-input:focus ~ .custom-file-label {
  border-color: hsla(0, 0%, 100%, 0.2);
}
.c-dark-theme .custom-file-input:disabled ~ .custom-file-label,
.c-dark-theme .custom-file-input[disabled] ~ .custom-file-label {
  background-color: hsla(0, 0%, 100%, 0.05);
}
.c-dark-theme .custom-file-label {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-color: rgba(0, 0, 21, 0.3);
}
.c-dark-theme .custom-file-label:after {
  color: #e1e1e1;
  background-color: #181924;
}
.c-dark-theme .custom-range::-webkit-slider-thumb {
  background-color: #4638c2;
}
.c-dark-theme .custom-range::-webkit-slider-thumb:active {
  background-color: #c4c0ed;
}
.c-dark-theme .custom-range::-moz-range-thumb {
  background-color: #4638c2;
}
.c-dark-theme .custom-range::-moz-range-thumb:active {
  background-color: #c4c0ed;
}
.c-dark-theme .custom-range::-moz-range-track {
  background-color: #c4c9d0;
}
.c-dark-theme .custom-range::-ms-thumb {
  background-color: #4638c2;
}
.c-dark-theme .custom-range::-ms-thumb:active {
  background-color: #c4c0ed;
}
.c-dark-theme .custom-range::-ms-fill-lower {
  background-color: #c4c9d0;
}
.c-dark-theme .custom-range::-ms-fill-upper {
  background-color: #c4c9d0;
}
.c-dark-theme .custom-range:disabled::-webkit-slider-thumb {
  background-color: #9da5b1;
}
.c-dark-theme .custom-range:disabled::-moz-range-thumb {
  background-color: #9da5b1;
}
.c-dark-theme .custom-range:disabled::-ms-thumb {
  background-color: #9da5b1;
}
.c-dark-theme .dropdown-menu {
  color: #e1e1e1;
  background-color: #34343b;
  border-color: #181924;
}

.c-dark-theme .dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #181924;
}
.c-dark-theme .dropdown-item {
  color: #e1e1e1;
}
.c-dark-theme .dropdown-item:focus,
.c-dark-theme .dropdown-item:hover {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .dropdown-item.active,
.c-dark-theme .dropdown-item:active {
  color: #fff;
  background-color: #4638c2;
}
.c-dark-theme .dropdown-item.disabled,
.c-dark-theme .dropdown-item:disabled {
  color: hsla(0, 0%, 88.2%, 0.6);
}
.c-dark-theme .dropdown-header {
  color: #e1e1e1;
}
.c-dark-theme .dropdown-item-text {
  color: #e1e1e1;
}
.c-dark-theme .c-footer {
  color: #e1e1e1;
  background: #1e1e29;
  border-top: 1px solid #181924;
}
.c-dark-theme .form-control {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-color: hsla(0, 0%, 100%, 0.15);
}
.c-dark-theme .form-control:focus {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.15);
  border-color: hsla(0, 0%, 100%, 0.2);
}
.c-dark-theme .form-control::-webkit-input-placeholder {
  color: hsla(0, 0%, 88.2%, 0.25);
}
.c-dark-theme .form-control:-ms-input-placeholder {
  color: hsla(0, 0%, 88.2%, 0.25);
}
.c-dark-theme .form-control::-ms-input-placeholder {
  color: hsla(0, 0%, 88.2%, 0.25);
}
.c-dark-theme .form-control::placeholder {
  color: hsla(0, 0%, 88.2%, 0.25);
}
.c-dark-theme .form-control:disabled,
.c-dark-theme .form-control[readonly] {
  background-color: hsla(0, 0%, 100%, 0.05);
}
.c-dark-theme select.form-control:focus::-ms-value {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .form-control-plaintext {
  color: #e1e1e1;
}

.c-dark-theme .c-header .c-subheader {
  margin-top: -1px;
  border-top: 1px solid #181924;
}
.c-dark-theme .c-header .c-header-brand {
  color: #fff;
  background-color: transparent;
}
.c-dark-theme .c-header .c-header-brand:focus,
.c-dark-theme .c-header .c-header-brand:hover {
  color: #fff;
}
.c-dark-theme .c-header .c-header-nav .c-header-nav-btn,
.c-dark-theme .c-header .c-header-nav .c-header-nav-link {
  color: hsla(0, 0%, 100%, 0.75);
}
.c-dark-theme .c-header .c-header-nav .c-header-nav-btn:focus,
.c-dark-theme .c-header .c-header-nav .c-header-nav-btn:hover,
.c-dark-theme .c-header .c-header-nav .c-header-nav-link:focus,
.c-dark-theme .c-header .c-header-nav .c-header-nav-link:hover {
  color: hsla(0, 0%, 100%, 0.9);
}
.c-dark-theme .c-header .c-header-nav .c-header-nav-btn.c-disabled,
.c-dark-theme .c-header .c-header-nav .c-header-nav-link.c-disabled {
  color: hsla(0, 0%, 100%, 0.25);
}
.c-dark-theme .c-header .c-header-nav .c-active > .c-header-nav-link,
.c-dark-theme .c-header .c-header-nav .c-header-nav-link.c-active,
.c-dark-theme .c-header .c-header-nav .c-header-nav-link.c-show,
.c-dark-theme .c-header .c-header-nav .c-show > .c-header-nav-link {
  color: #fff;
}
.c-dark-theme .c-header .c-header-toggler {
  color: hsla(0, 0%, 100%, 0.75);
  border-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .c-header .c-header-toggler-icon {
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMzAgMzAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggc3Ryb2tlPSdyZ2JhKDI1NSwyNTUsMjU1LDAuNzUpJyBzdHJva2Utd2lkdGg9JzIuMjUnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLW1pdGVybGltaXQ9JzEwJyBkPSdNNCA3aDIyTTQgMTVoMjJNNCAyM2gyMicvPjwvc3ZnPg==);
}
.c-dark-theme .c-header .c-header-toggler-icon:hover {
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMzAgMzAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggc3Ryb2tlPSdyZ2JhKDI1NSwyNTUsMjU1LDAuOSknIHN0cm9rZS13aWR0aD0nMi4yNScgc3Ryb2tlLWxpbmVjYXA9J3JvdW5kJyBzdHJva2UtbWl0ZXJsaW1pdD0nMTAnIGQ9J000IDdoMjJNNCAxNWgyMk00IDIzaDIyJy8+PC9zdmc+);
}
.c-dark-theme .c-header .c-header-text {
  color: hsla(0, 0%, 100%, 0.75);
}
.c-dark-theme .c-header .c-header-text a,
.c-dark-theme .c-header .c-header-text a:focus,
.c-dark-theme .c-header .c-header-text a:hover {
  color: #fff;
}

.c-dark-theme .input-group-text {
  color: hsla(0, 0%, 88.2%, 0.5);
  background-color: #181924;
  border-color: #181924;
}

.c-dark-theme .list-group-item-action:active {
  color: #e1e1e1;
  background-color: #4f5d73;
}
.c-dark-theme .list-group-item {
  color: #e1e1e1;
  background-color: inherit;
  border-color: #181924;
}
.c-dark-theme .list-group-item.disabled,
.c-dark-theme .list-group-item:disabled {
  color: hsla(0, 0%, 88.2%, 0.5);
  background-color: rgba(24, 25, 36, 0.8);
}
.c-dark-theme .list-group-item.active {
  color: #fff;
  background-color: #4638c2;
  border-color: #4638c2;
}
.c-dark-theme .list-group-item-primary {
  color: #241d6f;
  background-color: #cbc7ee;
}
.c-dark-theme .list-group-item-primary.list-group-item-action:focus,
.c-dark-theme .list-group-item-primary.list-group-item-action:hover {
  color: #241d6f;
  background-color: #b9b3e8;
}
.c-dark-theme .list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #241d6f;
  border-color: #241d6f;
}
.c-dark-theme .list-group-item-secondary {
  color: #282936;
  background-color: #cdcecf;
}
.c-dark-theme .list-group-item-secondary.list-group-item-action:focus,
.c-dark-theme .list-group-item-secondary.list-group-item-action:hover {
  color: #282936;
  background-color: #c0c1c3;
}
.c-dark-theme .list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #282936;
  border-color: #282936;
}
.c-dark-theme .list-group-item-success {
  color: #24543e;
  background-color: #cbe5d4;
}
.c-dark-theme .list-group-item-success.list-group-item-action:focus,
.c-dark-theme .list-group-item-success.list-group-item-action:hover {
  color: #24543e;
  background-color: #baddc6;
}
.c-dark-theme .list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #24543e;
  border-color: #24543e;
}
.c-dark-theme .list-group-item-info {
  color: #255084;
  background-color: #cce2f9;
}
.c-dark-theme .list-group-item-info.list-group-item-action:focus,
.c-dark-theme .list-group-item-info.list-group-item-action:hover {
  color: #255084;
  background-color: #b5d5f6;
}
.c-dark-theme .list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #255084;
  border-color: #255084;
}
.c-dark-theme .list-group-item-warning {
  color: #755721;
  background-color: #f7e7c4;
}
.c-dark-theme .list-group-item-warning.list-group-item-action:focus,
.c-dark-theme .list-group-item-warning.list-group-item-action:hover {
  color: #755721;
  background-color: #f4deae;
}
.c-dark-theme .list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #755721;
  border-color: #755721;
}
.c-dark-theme .list-group-item-danger {
  color: #6d3540;
  background-color: #f2d4d4;
}
.c-dark-theme .list-group-item-danger.list-group-item-action:focus,
.c-dark-theme .list-group-item-danger.list-group-item-action:hover {
  color: #6d3540;
  background-color: #ecc0c0;
}
.c-dark-theme .list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #6d3540;
  border-color: #6d3540;
}
.c-dark-theme .list-group-item-light {
  color: #101120;
  background-color: #c0c1c3;
}
.c-dark-theme .list-group-item-light.list-group-item-action:focus,
.c-dark-theme .list-group-item-light.list-group-item-action:hover {
  color: #101120;
  background-color: #b3b4b7;
}
.c-dark-theme .list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #101120;
  border-color: #101120;
}
.c-dark-theme .list-group-item-dark {
  color: #0c0d1d;
  background-color: #bebfc2;
}
.c-dark-theme .list-group-item-dark.list-group-item-action:focus,
.c-dark-theme .list-group-item-dark.list-group-item-action:hover {
  color: #0c0d1d;
  background-color: #b1b2b6;
}
.c-dark-theme .list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0c0d1d;
  border-color: #0c0d1d;
}
.c-dark-theme .list-group-accent .list-group-item.list-group-item-divider:before {
  background-color: #181924;
}
.c-dark-theme .list-group-item-accent-primary {
  border-left: 4px solid #4638c2;
}
.c-dark-theme .list-group-item-accent-secondary {
  border-left: 4px solid #4c4f54;
}
.c-dark-theme .list-group-item-accent-success {
  border-left: 4px solid #45a164;
}
.c-dark-theme .list-group-item-accent-info {
  border-left: 4px solid #4799eb;
}
.c-dark-theme .list-group-item-accent-warning {
  border-left: 4px solid #e1a82d;
}
.c-dark-theme .list-group-item-accent-danger {
  border-left: 4px solid #d16767;
}
.c-dark-theme .list-group-item-accent-light {
  border-left: 4px solid #282933;
}
.c-dark-theme .list-group-item-accent-dark {
  border-left: 4px solid #181924;
}

.c-dark-theme .modal-content {
  background-color: #2a2b36;
  border-color: #2a2b36;
}

.c-dark-theme .modal-header {
  border-color: #2a2b36;
}

// .c-dark-theme .modal-footer {
//   border-color: #2a2b36;
// }

.c-dark-theme .modal-primary .modal-content {
  border-color: #4638c2;
}
.c-dark-theme .modal-primary .modal-header {
  color: #fff;
  background-color: #4638c2;
}
.c-dark-theme .modal-secondary .modal-content {
  border-color: #4c4f54;
}
.c-dark-theme .modal-secondary .modal-header {
  color: #fff;
  background-color: #4c4f54;
}
.c-dark-theme .modal-success .modal-content {
  border-color: #45a164;
}
.c-dark-theme .modal-success .modal-header {
  color: #fff;
  background-color: #45a164;
}
.c-dark-theme .modal-info .modal-content {
  border-color: #4799eb;
}
.c-dark-theme .modal-info .modal-header {
  color: #fff;
  background-color: #4799eb;
}
.c-dark-theme .modal-warning .modal-content {
  border-color: #e1a82d;
}
.c-dark-theme .modal-warning .modal-header {
  color: #fff;
  background-color: #e1a82d;
}
.c-dark-theme .modal-danger .modal-content {
  border-color: #d16767;
}
.c-dark-theme .modal-danger .modal-header {
  color: #fff;
  background-color: #d16767;
}
.c-dark-theme .modal-light .modal-content {
  border-color: #282933;
}
.c-dark-theme .modal-light .modal-header {
  color: #fff;
  background-color: #282933;
}
.c-dark-theme .modal-dark .modal-content {
  border-color: #181924;
}
.c-dark-theme .modal-dark .modal-header {
  color: #fff;
  background-color: #181924;
}

.c-dark-theme .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.5);
}

.c-dark-theme .nav-tabs {
  border-color: #24252f;
}
.c-dark-theme .nav-tabs .nav-link:focus,
.c-dark-theme .nav-tabs .nav-link:hover {
  border-color: #24252f;
}
.c-dark-theme .nav-tabs .nav-link.disabled {
  color: hsla(0, 0%, 100%, 0.5);
}
.c-dark-theme .nav-tabs .nav-item.show .nav-link,
.c-dark-theme .nav-tabs .nav-link.active {
  color: #e1e1e1;
  background-color: #24252f;
  border-color: #24252f;
}
.c-dark-theme .nav-tabs-boxed .nav-tabs .nav-link.active {
  background-color: #24252f;
  border-bottom-color: #24252f;
}
.c-dark-theme .nav-tabs-boxed .tab-content {
  color: #e1e1e1;
  background-color: #24252f;
  border-color: #24252f;
}
.c-dark-theme .nav-tabs-boxed.nav-tabs-boxed-left .nav-link.active {
  border-color: #24252f;
}
.c-dark-theme .nav-tabs-boxed.nav-tabs-boxed-right .nav-link.active {
  border-color: #24252f;
}
.c-dark-theme .nav-pills .nav-link.active,
.c-dark-theme .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4638c2;
}
.c-dark-theme .nav-underline {
  border-color: #24252f;
}
.c-dark-theme .nav-underline-primary .nav-link.active,
.c-dark-theme .nav-underline-primary .show > .nav-link {
  color: #4638c2;
  border-color: #4638c2;
}
.c-dark-theme .nav-underline-secondary .nav-link.active,
.c-dark-theme .nav-underline-secondary .show > .nav-link {
  color: #4c4f54;
  border-color: #4c4f54;
}
.c-dark-theme .nav-underline-success .nav-link.active,
.c-dark-theme .nav-underline-success .show > .nav-link {
  color: #45a164;
  border-color: #45a164;
}
.c-dark-theme .nav-underline-info .nav-link.active,
.c-dark-theme .nav-underline-info .show > .nav-link {
  color: #4799eb;
  border-color: #4799eb;
}
.c-dark-theme .nav-underline-warning .nav-link.active,
.c-dark-theme .nav-underline-warning .show > .nav-link {
  color: #e1a82d;
  border-color: #e1a82d;
}
.c-dark-theme .nav-underline-danger .nav-link.active,
.c-dark-theme .nav-underline-danger .show > .nav-link {
  color: #d16767;
  border-color: #d16767;
}
.c-dark-theme .nav-underline-light .nav-link.active,
.c-dark-theme .nav-underline-light .show > .nav-link {
  color: #282933;
  border-color: #282933;
}
.c-dark-theme .nav-underline-dark .nav-link.active,
.c-dark-theme .nav-underline-dark .show > .nav-link {
  color: #181924;
  border-color: #181924;
}
.c-dark-theme .popover {
  background-color: #1e1e29;
  border-color: #181924;
}
.c-dark-theme .popover[data-popper-placement^='top'] > .popover-arrow:before {
  border-top-color: #181924;
}
.c-dark-theme .popover[data-popper-placement^='top'] > .popover-arrow:after {
  border-top-color: #1e1e29;
}
.c-dark-theme .popover[data-popper-placement^='right'] > .popover-arrow:before {
  border-right-color: #181924;
}
.c-dark-theme .popover[data-popper-placement^='right'] > .popover-arrow:after {
  border-right-color: #1e1e29;
}
.c-dark-theme .popover[data-popper-placement^='bottom'] > .popover-arrow:before {
  border-bottom-color: #181924;
}
.c-dark-theme .popover[data-popper-placement^='bottom'] > .popover-arrow:after {
  border-bottom-color: #1e1e29;
}
.c-dark-theme .popover[data-popper-placement^='bottom'] .popover-header:before {
  border-bottom-color: #181924;
}
.c-dark-theme .popover[data-popper-placement^='left'] > .popover-arrow:before {
  border-left-color: #181924;
}
.c-dark-theme .popover[data-popper-placement^='left'] > .popover-arrow:after {
  border-left-color: #1e1e29;
}

[dir='rtl'] .c-dark-theme .c-sidebar,
[dir='rtl'] .c-dark-theme .c-sidebar.c-sidebar-right,
html:not([dir='rtl']) .c-dark-theme .c-sidebar.c-sidebar-right {
  border: 0;
}
.c-dark-theme .c-sidebar .c-sidebar-close {
  color: #e1e1e1;
}
.c-dark-theme .c-sidebar .c-sidebar-brand,
.c-dark-theme .c-sidebar .c-sidebar-header {
  background: rgba(0, 0, 21, 0.2);
}
.c-dark-theme .c-sidebar .c-sidebar-form .c-form-control {
  color: #fff;
  background: rgba(0, 0, 21, 0.1);
  border: 0;
}
.c-dark-theme .c-sidebar .c-sidebar-form .c-form-control::-webkit-input-placeholder {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar .c-sidebar-form .c-form-control:-ms-input-placeholder {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar .c-sidebar-form .c-form-control::-ms-input-placeholder {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar .c-sidebar-form .c-form-control::placeholder {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar .c-sidebar-nav-title {
  color: hsla(0, 0%, 100%, 0.6);
}
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar .c-sidebar-nav-link {
  color: hsla(0, 0%, 100%, 0.8);
  background: 0 0;
}
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.6);
}
.c-dark-theme .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-active {
  color: #fff;
  background: hsla(0, 0%, 100%, 0.05);
}

.c-dark-theme .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon {
  color: #fff;
}
// .c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-items .c-sidebar-nav-link.c-active {
//   & > .c-sidebar-nav-icon {
//     color: #7267d3;
//   }
//   color: #7267d3;
// }
@media (-ms-high-contrast: none), (hover: hover) {
  // .c-dark-theme .c-sidebar .c-sidebar-nav-link:hover {
  //   background: hsla(0, 0%, 100%, 0.05);
  // }
  .c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle:hover,
  .c-dark-theme .c-sidebar .c-sidebar-nav-link:hover {
    color: #fff;
    // background: #4638c2;
    background: linear-gradient(45deg, #4638c2, #2d2587) !important;
  }
  .c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
  .c-dark-theme .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon {
    // .c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
    color: #fff;
  }
  .c-dark-theme .c-sidebar .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle:after,
  .c-dark-theme .c-sidebar :hover.c-sidebar-nav-dropdown-toggle:after {
    background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nI2ZmZicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+);
  }
}
.c-dark-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-disabled {
  color: #b3b3b3;
  background: 0 0;
}
.c-dark-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-disabled .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.6);
}
.c-dark-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle:hover,
.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-disabled:hover {
  color: #b3b3b3;
}
.c-dark-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-disabled:hover .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.6);
}
.c-dark-theme .c-sidebar .c-disabled:hover.c-sidebar-nav-dropdown-toggle:after,
.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-disabled:hover.c-sidebar-nav-dropdown-toggle:after {
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nI2ZmZicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+);
}
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle {
  position: relative;
}
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle:after {
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0ncmdiYSgyNTUsMjU1LDI1NSwwLjUpJyBkPSdNOS4xNDggMi4zNTJsLTQuMTQ4IDQuMTQ4IDQuMTQ4IDQuMTQ4cTAuMTQ4IDAuMTQ4IDAuMTQ4IDAuMzUydC0wLjE0OCAwLjM1MmwtMS4yOTcgMS4yOTdxLTAuMTQ4IDAuMTQ4LTAuMzUyIDAuMTQ4dC0wLjM1Mi0wLjE0OGwtNS43OTctNS43OTdxLTAuMTQ4LTAuMTQ4LTAuMTQ4LTAuMzUydDAuMTQ4LTAuMzUybDUuNzk3LTUuNzk3cTAuMTQ4LTAuMTQ4IDAuMzUyLTAuMTQ4dDAuMzUyIDAuMTQ4bDEuMjk3IDEuMjk3cTAuMTQ4IDAuMTQ4IDAuMTQ4IDAuMzUydC0wLjE0OCAwLjM1MnonLz48L3N2Zz4=);
}
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: rgba(0, 0, 0, 0.2);
}
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link {
  color: #fff;
}
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-disabled.c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled {
  color: #b3b3b3;
  background: 0 0;
}
.c-dark-theme
  .c-sidebar
  .c-sidebar-nav-dropdown.c-show
  .c-disabled.c-sidebar-nav-dropdown-toggle:hover,
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled:hover {
  color: #b3b3b3;
}
.c-dark-theme
  .c-sidebar
  .c-sidebar-nav-dropdown.c-show
  .c-disabled.c-sidebar-nav-dropdown-toggle:hover
  .c-sidebar-nav-icon,
.c-dark-theme
  .c-sidebar
  .c-sidebar-nav-dropdown.c-show
  .c-sidebar-nav-link.c-disabled:hover
  .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar .c-sidebar-nav-label {
  color: hsla(0, 0%, 100%, 0.6);
}
.c-dark-theme .c-sidebar .c-sidebar-nav-label:hover {
  color: #e1e1e1;
}
.c-dark-theme .c-sidebar .c-sidebar-nav-label .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.6);
}
.c-dark-theme .c-sidebar .c-progress {
  background-color: #4f505e !important;
}
.c-dark-theme .c-sidebar .c-sidebar-footer {
  background: rgba(0, 0, 21, 0.2);
}
.c-dark-theme .c-sidebar .c-sidebar-minimizer {
  background-color: rgba(0, 0, 21, 0.2);
}
.c-dark-theme .c-sidebar .c-sidebar-minimizer:before {
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0ncmdiYSgyNTUsMjU1LDI1NSwwLjUpJyBkPSdNOS4xNDggMi4zNTJsLTQuMTQ4IDQuMTQ4IDQuMTQ4IDQuMTQ4cTAuMTQ4IDAuMTQ4IDAuMTQ4IDAuMzUydC0wLjE0OCAwLjM1MmwtMS4yOTcgMS4yOTdxLTAuMTQ4IDAuMTQ4LTAuMzUyIDAuMTQ4dC0wLjM1Mi0wLjE0OGwtNS43OTctNS43OTdxLTAuMTQ4LTAuMTQ4LTAuMTQ4LTAuMzUydDAuMTQ4LTAuMzUybDUuNzk3LTUuNzk3cTAuMTQ4LTAuMTQ4IDAuMzUyLTAuMTQ4dDAuMzUyIDAuMTQ4bDEuMjk3IDEuMjk3cTAuMTQ4IDAuMTQ4IDAuMTQ4IDAuMzUydC0wLjE0OCAwLjM1MnonLz48L3N2Zz4=);
}
.c-dark-theme .c-sidebar .c-sidebar-minimizer.c-focus,
.c-dark-theme .c-sidebar .c-sidebar-minimizer:focus {
  outline: 0;
}
.c-dark-theme .c-sidebar .c-sidebar-minimizer:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.c-dark-theme .c-sidebar .c-sidebar-minimizer:hover:before {
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0nI2ZmZicgZD0nTTkuMTQ4IDIuMzUybC00LjE0OCA0LjE0OCA0LjE0OCA0LjE0OHEwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJsLTEuMjk3IDEuMjk3cS0wLjE0OCAwLjE0OC0wLjM1MiAwLjE0OHQtMC4zNTItMC4xNDhsLTUuNzk3LTUuNzk3cS0wLjE0OC0wLjE0OC0wLjE0OC0wLjM1MnQwLjE0OC0wLjM1Mmw1Ljc5Ny01Ljc5N3EwLjE0OC0wLjE0OCAwLjM1Mi0wLjE0OHQwLjM1MiAwLjE0OGwxLjI5NyAxLjI5N3EwLjE0OCAwLjE0OCAwLjE0OCAwLjM1MnQtMC4xNDggMC4zNTJ6Jy8+PC9zdmc+);
}
.c-dark-theme
  .c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link {
  background: #4638c2;
}
.c-dark-theme
  .c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-dark-theme
  .c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-link
  .c-sidebar-nav-icon {
  color: #fff;
}
.c-dark-theme
  .c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-disabled.c-sidebar-nav-dropdown-toggle,
.c-dark-theme
  .c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-link.c-disabled {
  background: #2a2b36;
}
.c-dark-theme
  .c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-disabled.c-sidebar-nav-dropdown-toggle
  .c-sidebar-nav-icon,
.c-dark-theme
  .c-sidebar.c-sidebar-minimized
  .c-sidebar-nav-item:hover
  > .c-sidebar-nav-link.c-disabled
  .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.6);
}
.c-dark-theme
  .c-sidebar.c-sidebar-minimized
  .c-sidebar-nav
  > .c-sidebar-nav-dropdown
  > .c-sidebar-nav-dropdown-items {
  background: #2a2b36;
}
.c-dark-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  background: #4638c2;
}

.c-dark-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary {
  background: #4638c2;
}
.c-dark-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle:hover,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary:hover {
  background: #3f32ae;
}
.c-dark-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary:hover .c-sidebar-nav-icon {
  color: #fff;
}
.c-dark-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary {
  background: #4c4f54;
}
.c-dark-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle:hover,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary:hover {
  background: #404247;
}
.c-dark-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary:hover .c-sidebar-nav-icon {
  color: #fff;
}
.c-dark-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-success {
  background: #45a164;
}
.c-dark-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-success .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle:hover,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-success:hover {
  background: #3d8f59;
}
.c-dark-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-success:hover .c-sidebar-nav-icon {
  color: #fff;
}
.c-dark-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-info {
  background: #4799eb;
}
.c-dark-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-info .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle:hover,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-info:hover {
  background: #308ce8;
}
.c-dark-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-info:hover .c-sidebar-nav-icon {
  color: #fff;
}
.c-dark-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning {
  background: #e1a82d;
}
.c-dark-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle:hover,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning:hover {
  background: #d69c1f;
}
.c-dark-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning:hover .c-sidebar-nav-icon {
  color: #fff;
}
.c-dark-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger {
  background: #d16767;
}
.c-dark-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle:hover,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger:hover {
  background: #cb5353;
}
.c-dark-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger:hover .c-sidebar-nav-icon {
  color: #fff;
}
.c-dark-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-light {
  background: #282933;
}
.c-dark-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-light .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle:hover,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-light:hover {
  background: #15151c;
}
.c-dark-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-light:hover .c-sidebar-nav-icon {
  color: #fff;
}
.c-dark-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark {
  background: #181924;
}
.c-dark-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark .c-sidebar-nav-icon {
  color: hsla(0, 0%, 100%, 0.7);
}
.c-dark-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle:hover,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark:hover {
  background: #0e0e15;
}
.c-dark-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-dark-theme .c-subheader .c-subheader-nav .c-subheader-nav-btn.c-disabled,
.c-dark-theme .c-subheader .c-subheader-nav .c-subheader-nav-link.c-disabled {
  color: hsla(0, 0%, 100%, 0.25);
}
.c-dark-theme .c-subheader .c-subheader-nav .c-active > .c-subheader-nav-link,
.c-dark-theme .c-subheader .c-subheader-nav .c-show > .c-subheader-nav-link,
.c-dark-theme .c-subheader .c-subheader-nav .c-subheader-nav-link.c-active,
.c-dark-theme .c-subheader .c-subheader-nav .c-subheader-nav-link.c-show {
  color: #fff;
}
.c-dark-theme .c-subheader .c-subheader-text {
  color: hsla(0, 0%, 100%, 0.75);
}
.c-dark-theme .c-subheader .c-subheader-text a,
.c-dark-theme .c-subheader .c-subheader-text a:focus,
.c-dark-theme .c-subheader .c-subheader-text a:hover {
  color: #fff;
}

.c-dark-theme .c-switch-input:focus ~ .c-switch-slider {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.15);
  border-color: hsla(0, 0%, 100%, 0.2);
}

.c-dark-theme .table {
  color: #e1e1e1;
  background-color: transparent;
}
.c-dark-theme .table td,
.c-dark-theme .table th {
  border-top-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .table thead th {
  border-bottom-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .table tbody + tbody {
  border-top-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .table-bordered {
  border-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .table-bordered td,
.c-dark-theme .table-bordered th {
  border-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 21, 0.05);
}
@media (-ms-high-contrast: none), (hover: hover) {
  .table-hover tbody tr:hover {
    color: #4f5d73;
    background-color: rgba(0, 0, 21, 0.075);
  }
  .c-legacy-theme .table-hover tbody tr:hover {
    color: #23282c;
    background-color: rgba(0, 0, 0, 0.075);
  }
  .c-dark-theme .table-hover tbody tr:hover {
    color: #e1e1e1;
    background-color: hsla(0, 0%, 100%, 0.1);
  }
}
.c-dark-theme .table-primary,
.c-dark-theme .table-primary > td,
.c-dark-theme .table-primary > th {
  color: #0a0b18;
  background-color: #cbc7ee;
}
.c-dark-theme .table-primary tbody + tbody,
.c-dark-theme .table-primary td,
.c-dark-theme .table-primary th,
.c-dark-theme .table-primary thead th {
  border-color: #9f98df;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .table-hover .table-primary:hover,
  .c-dark-theme .table-hover .table-primary:hover > td,
  .c-dark-theme .table-hover .table-primary:hover > th {
    background-color: #b9b3e8;
  }
}
.c-dark-theme .table-secondary,
.c-dark-theme .table-secondary > td,
.c-dark-theme .table-secondary > th {
  color: #0a0b18;
  background-color: #cdcecf;
}
.c-dark-theme .table-secondary tbody + tbody,
.c-dark-theme .table-secondary td,
.c-dark-theme .table-secondary th,
.c-dark-theme .table-secondary thead th {
  border-color: #a2a3a6;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .table-hover .table-secondary:hover,
  .c-dark-theme .table-hover .table-secondary:hover > td,
  .c-dark-theme .table-hover .table-secondary:hover > th {
    background-color: #c0c1c3;
  }
}
.c-dark-theme .table-success,
.c-dark-theme .table-success > td,
.c-dark-theme .table-success > th {
  color: #0a0b18;
  background-color: #cbe5d4;
}
.c-dark-theme .table-success tbody + tbody,
.c-dark-theme .table-success td,
.c-dark-theme .table-success th,
.c-dark-theme .table-success thead th {
  border-color: #9eceae;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .table-hover .table-success:hover,
  .c-dark-theme .table-hover .table-success:hover > td,
  .c-dark-theme .table-hover .table-success:hover > th {
    background-color: #baddc6;
  }
}
.c-dark-theme .table-info,
.c-dark-theme .table-info > td,
.c-dark-theme .table-info > th {
  color: #0a0b18;
  background-color: #cce2f9;
}
.c-dark-theme .table-info tbody + tbody,
.c-dark-theme .table-info td,
.c-dark-theme .table-info th,
.c-dark-theme .table-info thead th {
  border-color: #a0caf4;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .table-hover .table-info:hover,
  .c-dark-theme .table-hover .table-info:hover > td,
  .c-dark-theme .table-hover .table-info:hover > th {
    background-color: #b5d5f6;
  }
}
.c-dark-theme .table-warning,
.c-dark-theme .table-warning > td,
.c-dark-theme .table-warning > th {
  color: #0a0b18;
  background-color: #f7e7c4;
}
.c-dark-theme .table-warning tbody + tbody,
.c-dark-theme .table-warning td,
.c-dark-theme .table-warning th,
.c-dark-theme .table-warning thead th {
  border-color: #efd292;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .table-hover .table-warning:hover,
  .c-dark-theme .table-hover .table-warning:hover > td,
  .c-dark-theme .table-hover .table-warning:hover > th {
    background-color: #f4deae;
  }
}
.c-dark-theme .table-danger,
.c-dark-theme .table-danger > td,
.c-dark-theme .table-danger > th {
  color: #0a0b18;
  background-color: #f2d4d4;
}
.c-dark-theme .table-danger tbody + tbody,
.c-dark-theme .table-danger td,
.c-dark-theme .table-danger th,
.c-dark-theme .table-danger thead th {
  border-color: #e7b0b0;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .table-hover .table-danger:hover,
  .c-dark-theme .table-hover .table-danger:hover > td,
  .c-dark-theme .table-hover .table-danger:hover > th {
    background-color: #ecc0c0;
  }
}
.c-dark-theme .table-light,
.c-dark-theme .table-light > td,
.c-dark-theme .table-light > th {
  color: #0a0b18;
  background-color: #c0c1c3;
}
.c-dark-theme .table-light tbody + tbody,
.c-dark-theme .table-light td,
.c-dark-theme .table-light th,
.c-dark-theme .table-light thead th {
  border-color: #8b8b90;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .table-hover .table-light:hover,
  .c-dark-theme .table-hover .table-light:hover > td,
  .c-dark-theme .table-hover .table-light:hover > th {
    background-color: #b3b4b7;
  }
}
.c-dark-theme .table-dark,
.c-dark-theme .table-dark > td,
.c-dark-theme .table-dark > th {
  color: #0a0b18;
  background-color: #bebfc2;
}
.c-dark-theme .table-dark tbody + tbody,
.c-dark-theme .table-dark td,
.c-dark-theme .table-dark th,
.c-dark-theme .table-dark thead th {
  border-color: #87878d;
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .table-hover .table-dark:hover,
  .c-dark-theme .table-hover .table-dark:hover > td,
  .c-dark-theme .table-hover .table-dark:hover > th {
    background-color: #b1b2b6;
  }
}
.c-dark-theme .table-active,
.c-dark-theme .table-active > td,
.c-dark-theme .table-active > th {
  color: #fff;
  background-color: rgba(0, 0, 21, 0.2);
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .table-hover .table-active:hover,
  .c-dark-theme .table-hover .table-active:hover > td,
  .c-dark-theme .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.c-dark-theme .table-selected,
.c-dark-theme .table-selected > td,
.c-dark-theme .table-selected > th {
  color: #fff;
  background-color: rgba(0, 0, 21, 0.2);
}
.c-dark-theme .table-selected tbody + tbody,
.c-dark-theme .table-selected td,
.c-dark-theme .table-selected th,
.c-dark-theme .table-selected thead th {
  border-color: rgba(0, 0, 21, 0.2);
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-dark-theme .table-hover .table-selected:hover,
  .c-dark-theme .table-hover .table-selected:hover > td,
  .c-dark-theme .table-hover .table-selected:hover > th {
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.c-dark-theme .table tbody tr:focus {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .table .thead-dark th {
  color: #fff;
  background-color: rgba(0, 0, 21, 0.5);
  border-color: rgba(0, 0, 21, 0.9);
}
.c-dark-theme .table .thead-light th {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.05);
  border-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .table-dark {
  color: #fff;
  background-color: rgba(0, 0, 21, 0.5);
}
.c-dark-theme .table-dark td,
.c-dark-theme .table-dark th,
.c-dark-theme .table-dark thead th {
  border-color: rgba(0, 0, 21, 0.9);
}
.c-dark-theme .table-dark.table-striped tbody tr:nth-of-type(odd),
.c-legacy-theme .table-dark.table-striped tbody tr:nth-of-type(odd),
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: hsla(0, 0%, 100%, 0.05);
}
@media (-ms-high-contrast: none), (hover: hover) {
  .c-legacy-theme .table-dark.table-hover tbody tr:hover,
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: hsla(0, 0%, 100%, 0.075);
  }
  .c-dark-theme .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: hsla(0, 0%, 100%, 0.1);
  }
}
.c-dark-theme .table-outline {
  border-color: hsla(0, 0%, 100%, 0.1);
}
.c-dark-theme .toast {
  color: #e1e1e1;
  background-color: #37373d;
  border-color: transparent;
}
.c-dark-theme .toast-header {
  color: #e1e1e1;
  background-color: #37373d;
  border-color: rgba(0, 0, 21, 0.2);
}

.c-dark-theme .toast-success {
  color: #e1e1e1;
  background-color: #45a164;
  border-color: transparent;
}
.c-dark-theme .toast-header-success {
  color: #e1e1e1;
  background-color: #367d4e;
  border-color: rgba(69, 161, 100, 0.2);
}

.c-dark-theme .toast-danger {
  color: #e1e1e1;
  background-color: #d16767;
  border-color: transparent;
}
.c-dark-theme .toast-header-danger {
  color: #e1e1e1;
  background-color: #c54040;
  border-color: rgba(209, 103, 103, 0.2);
}

.c-dark-theme .bg-primary {
  background-color: #4638c2 !important;
}
.c-dark-theme a.bg-primary:focus,
.c-dark-theme a.bg-primary:hover,
.c-dark-theme button.bg-primary:focus,
.c-dark-theme button.bg-primary:hover {
  background-color: #382d9a !important;
}
.c-dark-theme .bg-secondary {
  background-color: #4c4f54 !important;
}
.c-dark-theme a.bg-secondary:focus,
.c-dark-theme a.bg-secondary:hover,
.c-dark-theme button.bg-secondary:focus,
.c-dark-theme button.bg-secondary:hover {
  background-color: #343639 !important;
}
.c-dark-theme .bg-success {
  background-color: #45a164 !important;
}
.c-dark-theme a.bg-success:focus,
.c-dark-theme a.bg-success:hover,
.c-dark-theme button.bg-success:focus,
.c-dark-theme button.bg-success:hover {
  background-color: #367d4e !important;
}
.c-dark-theme .bg-info {
  background-color: #4799eb !important;
}
.c-dark-theme a.bg-info:focus,
.c-dark-theme a.bg-info:hover,
.c-dark-theme button.bg-info:focus,
.c-dark-theme button.bg-info:hover {
  background-color: #1a80e6 !important;
}
.c-dark-theme .bg-warning {
  background-color: #e1a82d !important;
}
.c-dark-theme a.bg-warning:focus,
.c-dark-theme a.bg-warning:hover,
.c-dark-theme button.bg-warning:focus,
.c-dark-theme button.bg-warning:hover {
  background-color: #c08c1b !important;
}
.c-dark-theme .bg-danger {
  background-color: #d16767 !important;
}
.c-dark-theme a.bg-danger:focus,
.c-dark-theme a.bg-danger:hover,
.c-dark-theme button.bg-danger:focus,
.c-dark-theme button.bg-danger:hover {
  background-color: #c54040 !important;
}
.c-dark-theme .bg-light {
  background-color: #282933 !important;
}
.c-dark-theme a.bg-light:focus,
.c-dark-theme a.bg-light:hover,
.c-dark-theme button.bg-light:focus,
.c-dark-theme button.bg-light:hover {
  background-color: #0a0a0d !important;
}
.c-dark-theme .bg-dark {
  background-color: #181924 !important;
}
.c-dark-theme a.bg-dark:focus,
.c-dark-theme a.bg-dark:hover,
.c-dark-theme button.bg-dark:focus,
.c-dark-theme button.bg-dark:hover {
  background-color: #040405 !important;
}

.c-dark-theme .bg-gradient-primary {
  background: #2d2587 !important;
  background: linear-gradient(45deg, #4638c2, #2d2587) !important;
  border-color: #2d2587 !important;
}
.c-dark-theme .bg-gradient-secondary {
  background: #fff !important;
  background: linear-gradient(45deg, #d1d2d3, #fff) !important;
  border-color: #fff !important;
}
.c-dark-theme .bg-gradient-success {
  background: #2e8c47 !important;
  background: linear-gradient(45deg, #45a164, #2e8c47) !important;
  border-color: #2e8c47 !important;
}
.c-dark-theme .bg-gradient-info {
  background: #4280b4 !important;
  background: linear-gradient(45deg, #4799eb, #4280b4) !important;
  border-color: #4280b4 !important;
}
.c-dark-theme .bg-gradient-warning {
  background: #dd9124 !important;
  background: linear-gradient(45deg, #e1a82d, #dd9124) !important;
  border-color: #dd9124 !important;
}
.c-dark-theme .bg-gradient-danger {
  background: #c14f4f !important;
  background: linear-gradient(45deg, #d16767, #c14f4f) !important;
  border-color: #c14f4f !important;
}
.c-dark-theme .bg-gradient-light {
  background: #fff !important;
  background: linear-gradient(45deg, #e8e8e8, #fff) !important;
  border-color: #fff !important;
}
.c-dark-theme .bg-gradient-dark {
  background: #292a2b !important;
  background: linear-gradient(45deg, #4c4f54, #292a2b) !important;
  border-color: #292a2b !important;
}
.c-dark-theme .border {
  border: 1px solid #181924 !important;
}
.c-dark-theme .border-top {
  border-top: 1px solid #181924 !important;
}
.c-dark-theme .border-right {
  border-right: 1px solid #181924 !important;
}
.c-dark-theme .border-bottom {
  border-bottom: 1px solid #181924 !important;
}
.c-dark-theme .border-left {
  border-left: 1px solid #181924 !important;
}
.c-dark-theme .border-primary {
  border: 1px solid #4638c2 !important;
}
.c-dark-theme .border-secondary {
  border: 1px solid #4c4f54 !important;
}
.c-dark-theme .border-success {
  border: 1px solid #45a164 !important;
}
.c-dark-theme .border-info {
  border: 1px solid #4799eb !important;
}
.c-dark-theme .border-warning {
  border: 1px solid #e1a82d !important;
}
.c-dark-theme .border-danger {
  border: 1px solid #d16767 !important;
}
.c-dark-theme .border-light {
  border: 1px solid #282933 !important;
}
.c-dark-theme .border-dark {
  border: 1px solid #181924 !important;
}

.c-app:not(.c-dark-theme):not(.c-legacy-theme) .c-d-default-none,
.c-dark-theme .c-d-dark-none,
.c-default-theme .c-d-default-none,
.c-legacy-theme .c-d-legacy-none,
.d-xl-down-none {
  display: none !important;
}

.c-dark-theme .text-primary {
  color: #4638c2 !important;
}
.c-dark-theme a.text-primary:focus,
.c-dark-theme a.text-primary:hover {
  color: #312787 !important;
}
.c-dark-theme .text-secondary {
  color: #4c4f54 !important;
}
.c-dark-theme a.text-secondary:focus,
.c-dark-theme a.text-secondary:hover {
  color: #28292c !important;
}
.c-dark-theme .text-success {
  color: #45a164 !important;
}
.c-dark-theme a.text-success:focus,
.c-dark-theme a.text-success:hover {
  color: #2e6b43 !important;
}
.c-dark-theme .text-info {
  color: #4799eb !important;
}
.c-dark-theme a.text-info:focus,
.c-dark-theme a.text-info:hover {
  color: #1773cf !important;
}
.c-dark-theme .text-warning {
  color: #e1a82d !important;
}
.c-dark-theme a.text-warning:focus,
.c-dark-theme a.text-warning:hover {
  color: #a97b18 !important;
}
.c-dark-theme .text-danger {
  color: #d16767 !important;
}
.c-dark-theme a.text-danger:focus,
.c-dark-theme a.text-danger:hover {
  color: #b53636 !important;
}
.c-dark-theme .text-light {
  color: #282933 !important;
}
.c-dark-theme a.text-light:focus,
.c-dark-theme a.text-light:hover {
  color: #000 !important;
}
.c-dark-theme .text-dark {
  color: #181924 !important;
}
.c-dark-theme a.text-dark:focus,
.c-dark-theme a.text-dark:hover {
  color: #000 !important;
}
.c-dark-theme .c-email-app {
  background-color: #24252f;
}
.c-dark-theme .c-email-app .c-toolbar {
  border-color: #181924;
}
.c-dark-theme .c-email-app .c-message {
  color: #e1e1e1;
}
.c-dark-theme .c-email-app .c-message.c-message-read {
  color: #959595;
}
.c-dark-theme .c-email-app .c-message-details {
  border-color: #181924;
}

.comboBoxInput {
  // position: relative;
  z-index: 100;
  background-color: transparent;
  display: flex;

  flex: 0 0 40px;
  justify-content: flex-end;
  // width: 100%;
  padding: 0;
  cursor: pointer;
  border: 0;
}

.c-dark-theme .comboBoxInput:before {
  opacity: 0.75;
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxMSAxNCc+PHBhdGggZmlsbD0ncmdiYSgyNTUsMjU1LDI1NSwwLjUpJyBkPSdNOS4xNDggMi4zNTJsLTQuMTQ4IDQuMTQ4IDQuMTQ4IDQuMTQ4cTAuMTQ4IDAuMTQ4IDAuMTQ4IDAuMzUydC0wLjE0OCAwLjM1MmwtMS4yOTcgMS4yOTdxLTAuMTQ4IDAuMTQ4LTAuMzUyIDAuMTQ4dC0wLjM1Mi0wLjE0OGwtNS43OTctNS43OTdxLTAuMTQ4LTAuMTQ4LTAuMTQ4LTAuMzUydDAuMTQ4LTAuMzUybDUuNzk3LTUuNzk3cTAuMTQ4LTAuMTQ4IDAuMzUyLTAuMTQ4dDAuMzUyIDAuMTQ4bDEuMjk3IDEuMjk3cTAuMTQ4IDAuMTQ4IDAuMTQ4IDAuMzUydC0wLjE0OCAwLjM1MnonLz48L3N2Zz4=);
}
.c-dark-theme .comboBoxInput:hover:before {
  opacity: 1;
}

.c-dark-theme .comboBoxInput.-open::before {
  transform: rotate(90deg);
}
.c-dark-theme .comboBoxInput::before {
  transform: rotate(-90deg);
  display: inline;
  width: 40px;
  height: 40px;
  content: '';
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12.5px;
  transition: 0.2s;
  position: absolute;
  // background-color: black;
  top: -1px;
  right: 0;
}

.border-transparent {
  border-color: transparent !important;
}

.notes-button {
  position: absolute;
  display: inline-flex;
  bottom: 8px !important;
  right: 4px !important;
  align-items: center;
  outline: none;
  background-color: transparent;
  border: none;
  border-left: 1px solid #d6d6d6;
  cursor: pointer;
}

.notes-area {
  resize: none;
  width: 100%;
  padding: 18px 45px 15px 12px;
  outline: none;
}

// .Toastify__toast {
// }
// .Toastify__toast--rtl {
// }
// .Toastify__toast--dark {
// }
// .Toastify__toast--default {
// }
.Toastify__toast--info {
  background-color: $info !important;
}
.Toastify__toast--success {
  background-color: $success !important;
}
.Toastify__toast--warning {
  background-color: $warning !important;
}
.Toastify__toast--error {
  background-color: $danger !important;
}
// .Toastify__toast-body {
// }

.c-dark-theme .input-field-root {
  color: #ffff;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-color: hsla(0, 0%, 100%, 0.15);
  &::placeholder,
  &::-webkit-input-placeholder {
    color: hsla(0, 0%, 88.2%, 0.25);
    opacity: 1;
  }
  box-shadow: 0 1px 1px 0 rgba(var(--elevation-base-color), 0.14),
    0 2px 1px -1px rgba(var(--elevation-base-color), 0.12),
    0 1px 3px 0 rgba(var(--elevation-base-color), 0.2);
}

.input-field-combo-root {
  &.upscaled {
    padding: 18px 5px 0px !important;
  }
  &:not(.upscaled) {
    padding: 15px 5px 0px;
  }
  position: relative;
}

.c-dark-theme .input-field-root {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.1);
  border: 1px solid hsla(0, 0%, 100%, 0.15);
  border-radius: 0.25rem;
  background-clip: padding-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  //
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  label {
    color: #0000;
    
  }
  &.upscaled {
    /* &:not(.textarea) {
      height: 3rem;
    } */
    font-size: 12pt !important;
    label {
      left: 7.4px !important;
      font-size: 0.65rem !important;
      font-weight: 200;
      
    }
    /* input[type='text'],input[type='email'],input[type='checkbox'], input  {
      font-size: 12pt !important;
      height: 25px;
    } */
    & .input-field {
      font-size: 12pt;
    }

    .c-switch-input {
      height: 25px !important;
    }
  }
  &.login {
    flex: 1;
    border-color: #181924;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
//  * style input
.c-dark-theme .input-field {
  color: #e1e1e1;
  background-color: transparent;
  border-color: transparent;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5;
}
.c-dark-theme .input-field-root:not(.RadioGroup):focus-within {
  color: #e1e1e1;
  background-color: hsla(0, 0%, 100%, 0.15);
  border-color: hsla(0, 0%, 100%, 0.2);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(50, 31, 219, 0.25);
}

.c-dark-theme .input-field-root.disabled,
.c-dark-theme .input-field-root.readonly {
  background-color: hsla(0, 0%, 100%, 0.05);
  border-color: hsla(0, 0%, 100%, 0.1);
  label {
    color: rgba(241, 241, 241, 0.6);
  }
  .input-field {
    color: rgba(241, 241, 241, 0.4);
  }
}

.c-dark-theme .card-header .nav-tabs {
  // border-color: transparent;
  // border-color: rgba(255, 255, 255, 0.075);
  border: none;
  .nav-item {
    // border-color: transparent;

    .nav-link {
      &:not(.nav-link-md) {
        font-size: 12px;
        padding: 0.25rem 0.5rem;
      }
      &.nav-link-md {
        font-size: 13px;
        padding: 0.5rem 0.75rem;
      }
      &:focus,
      &:hover {
        border-color: rgba(255, 255, 255, 0.075);
      }
    }
    .show .nav-link,
    .nav-link.active {
      border-color: rgba(255, 255, 255, 0.075) rgba(255, 255, 255, 0.075) #24252f;
      // color: #768192;
      color: rgba(255, 255, 255, 0.87);
    }
  }
}

.table-pagination {
  user-select: none;
}

.table-pagination-page:hover {
  color: #7267d3 !important;
}

// .styles-module_panel__17oVz .table-pagination {
//   display: none;
// }
// .styles-module_panel__17oVz .styles-module_ml-sm-auto__1yqRw {
//   display: none;
// }
// .styles-module_panel__17oVz .styles-module_text-light__QizW4 {
//   display: none;
// }

.c-switch {
  width: 45px;
  height: 26px;
  margin-top: 4px;
  margin-bottom: 0px;
  position: relative;
}
.c-switch-slider::before {
  width: 20px;
  height: 20px;
  left: 1px;
  top: 1px !important;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.c-switch-input:checked ~ .c-switch-slider::before {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  right: 1px;
  left: unset;
}
.cst-switch .input-field-root {
  display: block !important;
}

.custom-styles {
  padding: 0px 8px !important;
}
.custom-styles .c-switch {
  height: 24px !important;
}
.custom-styles label {
  margin-bottom: 0px !important;
  position: relative !important;
  left: 0 !important;
  top: -4px !important;
  margin-top: 0px !important;
}

.table-layout-fixed {
  table-layout: fixed;
}

.table-cell-fixed {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: initial;
}

.c-loading-button {
  position: relative;
  overflow: hidden;
}
.c-loading-button-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}
.c-loading-button-spinner {
  margin-right: 1rem;
  margin-left: -2rem;
  opacity: 0;
  transition: margin 0.3s, opacity 0.3s, border 0.3s;
}
.c-loading-button-loading .c-loading-button-spinner {
  width: 1rem;
  margin-left: 0;
  opacity: 1;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.ck.ck-content.ck-editor__editable {
  color: black;
}

form.input-field {
  margin-top: 0.5rem;
  --main-color: #4638c2;
}

/* .input-field {
   width: 20rem !important;
} */
.input-field-button {
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &.text-muted {
    cursor: progress;
  };

  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 15px;
  top: 0;
  cursor: pointer;
  user-select: none;
}


.input-field-root {
  border: 1px solid #ccc;
  font-size: 1rem;
  flex-wrap: inherit !important;
  label {
    color: #000 !important;
  }
  display: block !important;
}
/* .input-field-root.c-switch {
  font-size: 4rem !important;
} */
.input-field-root-multiple {
  flex-wrap: wrap !important;
  height: 3rem;
}

.Notes-send-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 0;
  background: none;
  outline: none !important;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #d6d6d6;
  padding-left: 0.25rem;
}

.item-check { 
  grid-area: check; 
  border-radius: 5px; 
  background-color: #4638c2;
  align-self: center;
}


.Notes-notes-area {
  height: 120px;
}

.control label {
  display: inline;
}

.c-dark-theme .list-group-item {
  color: rgba(255, 255, 255, 0.87);
  background-color: inherit;
  border-color: rgba(255, 255, 255, 0.075);
}

.dropdown-menu.show {
  left: auto !important;
  right: 0;
}

.ms-login-btn {
  opacity: 1;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  .disabled {
    cursor: progress;
    opacity: 0.5;
  }
}

.ck-editor__editable {
  min-height: 500px;
}

.c-app:not(.c-legacy-theme)
  .btn:not([class*='ghost']):not([class*='link']):not([class*='outline']):not([class*='transparent']) {
  border: 0;
  box-shadow: 0 1px 1px 0 rgba(var(--elevation-base-color), 0.14),
    0 2px 1px -1px rgba(var(--elevation-base-color), 0.12),
    0 1px 3px 0 rgba(var(--elevation-base-color), 0.2);
}

button:focus {
  outline: none;
}

.pac-container {
  z-index: 2000;
}

.query-builder-trash-icon,
.mapTable-trash-icon {
  transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: pointer;
  opacity: 0.75;
  &:hover {
    opacity: 1;
    color: #d16767;
    transform: scale(1.15);
  }
}
.MuiAutocomplete-popper {
  li {
    font-size: 0.85rem;
  }
}
.MuiAutocomplete-root:hover .MuiAutocomplete-clearIndicator,
.MuiAutocomplete-root:hover .MuiAutocomplete-clearIndicatorDirty {
  visibility: visible;
}
.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator {
  margin-right: 0.5rem;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.MuiIconButton-label .MuiSvgIcon-root {
  color: black;
}

.detail-tab-section-pane {
  overflow-y: auto;
  max-height: 70vh;
  padding: 0 1rem;
  overflow-x: hidden;
}

.campaign-detail-tab-section-pane {
  overflow-y: auto;
  padding: 0 1rem;
  overflow-x: hidden;
}

.custom-icon-picker.icon-picker-lg {
  max-height: 90vh;
  .icons .icon-li svg {
    color: #e0e0e0;
  }
}

.c-sidebar-nav-dropdown {
  user-select: none;
}

.control {
  & + label {
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

.control[disabled] {
  & + label {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.upscale-icon {
  opacity: 0;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    opacity: 1;
  }
}

.vital-spinner-loader {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(360deg, #00cdff -16.09%, #33d1f9 13.54%, #1080bf 81.05%);
  animation: animate 1.2s linear infinite;
  -webkit-animation: animate 1.2s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes animate {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

.vital-spinner-loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(360deg, #00cdff -16.09%, #33d1f9 13.54%, #1080bf 81.05%);
}

.vital-spinner-loader span:nth-child(1) {
  filter: blur(5px);
}

.vital-spinner-loader span:nth-child(2) {
  filter: blur(10px);
}

.vital-spinner-loader span:nth-child(3) {
  filter: blur(25px);
}

.vital-spinner-loader span:nth-child(4) {
  filter: blur(50px);
}

.vital-spinner-loader:after {
  content: '';
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: #f1f1f1;
  border: 5px solid white;
  border-radius: 50%;
}

.vital-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -16.5px;
}

.notes-container {
  background-color: '#ddd';
  padding: '5px';
}
.notes-container-note {
  width:'100%';
  border:'solid #eee 1px';
  text-align: 'center';
  margin-bottom: '2px';
  margin-top: '2px';
  padding: '5px';
  border-radius: '10px';
  background-color: '#fff';
}
.notes-date {
  color: '#111';
  font-size: '12px';
  background-color: '#eee';
  padding: '5px';
  margin-top: '25px';
  border-radius: '10px';
}
.note-container {
  display: 'flex';
}
.note-avatar {
  border: 'solid 1px #ddd';
  border-radius: '100%';
}
.note-text {
  color: '#000';
  margin-left: '1rem';
  align-self: 'center';
}