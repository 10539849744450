.avatar-upload {
  position: relative;
  max-width: 205px;
  margin: auto;
}
.react-pdf__Page__canvas {
  margin: 1em auto;
  width: 100% !important;
  height: 100% !important;
}
.react-pdf__Page.pdf-page { display: flex; justify-content: center; }
.react-pdf__Page__textContent {display: none;}

.linkAnnotation {
  display: none !important;
}
