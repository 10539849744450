::-webkit-scrollbar {
  // background-color: #fff;
  width: 5px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  border-radius: 16px;
  // background-color: rgba($color: #0e0e15, $alpha: 0.5);
  background-color: rgba($color: #fff, $alpha: 1);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: rgba($color: #0e0e15, $alpha: 0.5);
  border-radius: 16px;
  // border: 5px solid #EEEEEE;
  border: 5px solid rgba($color: #0e0e15, $alpha: 0.1);
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
