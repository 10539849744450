// Here you can add other styles

$theme-colors: (
  'primary': #4638c2,
  'secondary': #4c4f54,
  'success': #45a164,
  'info': #4799eb,
  'warning': #e1a82d,
  'danger': #d16767,
  'light': #20202a,
  'dark': #181924,
);
@import '~react-image-gallery/styles/scss/image-gallery.scss';
//@import '../../node_modules/@coreui/coreui/scss/coreui';

.c-dark-theme .mini-card {
  display: flex;
  margin-top: 0.5rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.05);
}

// sidebare padding
.c-sidebar-nav-dropdown-items .c-sidebar-nav-item .c-sidebar-nav-link {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 4rem !important;
}

.chip-root {
  color: #fff;
  border: none;
  cursor: default;
  height: 32px;
  display: inline-flex;
  outline: 0;
  padding: 0;
  font-size: 0.8125rem;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(255, 255, 255, 0.1);
  .chip-avatar {
    color: #e0e0e0;
    width: 24px;
    height: 24px;
    font-size: 0.75rem;
    margin-left: 5px;
    margin-right: -6px;
  }
}

.mini-card .notes-avatar {
  color: #e0e0e0;
  width: 34px;
  height: 34px;
  font-size: 1rem;
  // margin-left: 5px;
  // margin-right: -6px;
}

.chip-clickable {
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 0.25);
  }
}

.avatar-root {
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
}

.avatar-colorDefault {
  color: #121212;
  background-color: #757575;
}

.avatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.chip-label {
  overflow: hidden;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
}

.service-tab .input-field-input {
  width: 100%;
}
.service-tab .input-field-input .control--checkbox {
  padding: 15px 15px 15px 17px;
  background: gray;
  border-radius: 27px;
  margin-bottom: 8px !important;
}

.service-tab .input-field-input .control--checkbox span {
  font-size: 15px;
  padding-left: 6px;
  vertical-align: middle;
}

.service-tab .control input:checked + label:before {
  background: #3b2fa4;
  border-color: #3b2fa4;
}
.service-tab .control label:before {
  width: 20px;
  height: 20px;
}

.service-tab .control input:checked + label:after {
  width: 20px;
  height: 20px;
}
.custom-icon-picker [class^='cil-'],
[class*=' cil-'] {
  color: #000 !important;
}

.table-pagination {
  font-size: 14px !important;
}
.styles-module_text-light__QizW4 small {
  color: #333;
  font-size: 14px !important;
}

.styles-module_pagination__2A9ak li button {
  color: #333 !important;
  font-size: 14px !important;
}

.styles-module_pagination__2A9ak li small strong {
  color: #333 !important;
  font-size: 14px !important;
  font-weight: 400;
}

.styles-module_pagination__2A9ak li small {
  margin-top: 0px !important;
}
.styles-module_table__3SimF tbody tr:hover {
  background: #ededed;
}
.styles-module_table__3SimF thead {
  background: #3c4b64;
  color: #fff;
}
table tr th .styles-module_d-xs-flex__36waj {
  position: relative;
}
table tr th .styles-module_mr-xs-auto__17XcS {
  position: absolute;
  right: 0px;
}
table tr td .styles-module_btn__2N9RR {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-size: 14px !important;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem !important;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-card-body {
  max-height: calc(100vh - 185px) !important;
  overflow: auto !important;
}


.campaign-card-body {
  overflow: auto !important;
}

.phones-card-body {
  overflow: auto !important;
}
.c-sidebar .c-sidebar-minimizer::before {
  visibility: hidden;
}
.custom-radio {
  border: 1px solid #ccc;
  // padding: 0.25rem;
  font-size: 1rem;

  label {
    color: #000 !important;
    margin-right: 0.5rem !important;
    // padding-left: 0.5rem !important;
  }
  .input-field-input div {
    display: inline-block;
    margin-right: 8px !important;
    margin-bottom: 0px !important;
    margin-top: 2px !important;
    label {
      padding-left: 18px !important;
    }
  }
}

.custom-radio [type='radio']:checked + label:after,
.custom-radio [type='radio']:not(:checked) + label:after {
  width: 8px !important;
  height: 8px !important;
  top: 5px !important;
  left: 3px !important;
}

.custom-radio [type='radio']:checked + label:before,
.custom-radio [type='radio']:not(:checked) + label:before {
  left: 0px !important;
  top: 2px !important;
  width: 14px !important;
  height: 14px !important;
}

.custom-icon {
  text-align: center;
  width: 32px;
  margin: auto;
  height: 32px;
  border: 1px solid #6e6e6e;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.border-none {
  border: none !important;
}

.login.login-with-news-feed,
.register.register-with-news-feed {
  min-height: 100vh;
  width: 100%;
}

.login.login-with-news-feed .news-feed,
.register.register-with-news-feed .news-feed {
  position: fixed;
  left: 0;
  right: 500px;
  top: 0;
  bottom: 0;
  overflow: hidden;
  transform: translateZ(0);
}
.news-image {
  background-image: url('../assets/img/login_bg.jpeg');
}

.login.login-with-news-feed .news-feed .news-image,
.register.register-with-news-feed .news-feed .news-image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.login.login-with-news-feed .login-container,
.login.login-with-news-feed .register-container,
.register.register-with-news-feed .login-container,
.register.register-with-news-feed .register-container {
  width: 500px;
  background: var(--app-component-bg);
  padding: 60px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
}

.login.login-with-news-feed .login-header,
.register.register-with-news-feed .login-header {
  position: relative;
  display: flex;
  align-items: center;
}
.mb-30px {
  margin-bottom: 30px !important;
}
.login.login-with-news-feed .login-header .brand,
.register.register-with-news-feed .login-header .brand {
  padding: 0;
  font-size: 28px;
  color: #20252a;
  font-weight: 300;
  flex: 1;
}

.login.login-with-news-feed .login-header .brand .small,
.login.login-with-news-feed .login-header .brand small,
.register.register-with-news-feed .login-header .brand .small,
.register.register-with-news-feed .login-header .brand small {
  font-size: 14px;
  display: block;
  color: #817b7b;
  font-weight: 400;
}

.text-gray-600 {
  color: #817b7b;
}
.action-copy {
  position: fixed;
  bottom: 20px;
}

/* Workflow Page Start */
.fz18 {
  font-size: 18px;
}
.fz20 {
  font-size: 20px;
}
.fz22 {
  font-size: 22px;
}
.line {
  width: 100%;
  height: 2px;
  background-color: #5f6062;
}
.gc {
  color: #6ba23a;
}
.clr-g {
  color: #808184;
}
.btn-grey {
  color: #000;
  background-color: #d5dee6;
  border-color: #d5dee6;
}
.btn-gc {
  color: #fff;
  background-color: #2f3c54;
  border-color: #2f3c54;
}
.btn-gw {
  width: 260px;
}
.btn-aw {
  width: 180px;
}
.bgbr {
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 3px;
}
.action-th {
  width: 185px;
}
.workflow table th {
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  height: 35px;
  color: #808184;
  background-color: rgb(248, 248, 248);
}
.workflow table td {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  color: rgb(116, 116, 116);
}
.action-btn,
.action-btn:hover {
  color: #000;
  background-color: #f2f2f2;
  border-color: #ececec;
  font-size: 12px;
}
.w-400 {
  max-width: 400px;
}
.me-1 {
  margin-right: 5px;
}
.w-50 {
  width: 50%;
}
.text-end {
  text-align: right;
}

// Breadbrumb
.breadcrumbes-container {
  height: 90px;
  padding: 10px;
  position: relative;
}

.swiper-slide {
  text-align: left;
  font-size: 14px;
  background: #f2f2f2;
  color: #808184;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 3px;
  height: 70px !important;
  width: 200px !important;
  display: inline-grid !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swiper-slide:last-child {
  padding-left: 30px !important;
  .swipper-icon {
    color: white;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 22px;
    left: 4px;
  }
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  font-size: 24px !important;
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  font-size: 24px !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swipper-icon {
  padding-right: 5px;
  color: #5c636a;
}

.swiper-button-prev {
  background-color: #5c636a;
  border-radius: 3px;
  color: white !important;
  width: 32px !important;
  height: 70px !important;
  margin-top: -35px !important;
  left: 0px !important;
}

.swiper-button-next {
  background-color: #5c636a;
  border-radius: 3px;
  color: white !important;
  overflow: hidden;
  width: 32px !important;
  height: 70px !important;
  margin-top: -35px !important;
  right: 0px !important;
}

.swiper-icon-arrow-right {
  position: absolute;
  right: -25px;
}

.non-auth-content {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.non-auth-form {
  width: 500px;
  padding: 15px 30px 30px 30px;
}
.non-auth-header {
  text-align: center;
  font-size: 31px;
  font-family: sans-serif;
}

.breadCrumbsBtn {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  padding: 10px;
}
.breadCrumbsBtn .dropdown-toggle {
  width: 220px;
  height: 70px;
  font-size: 18px;
  background-color: #5b6369;
  border-color: #5b6369;
  border-radius: 3px;
  font-weight: bold;
}
.breadCrumbsBtn .dropdown-toggle::after {
  content: '';
  border: none;
}
.breadCrumbsBtn .show > .btn-info.dropdown-toggle,
.breadCrumbsBtn .btn-info:focus,
.breadCrumbsBtn .btn-info.focus {
  background-color: #5b6369 !important;
  border-color: #5b6369 !important;
}
.breadCrumbsBtn .dropdown-menu {
  width: 220px;
  font-size: 14px;
}

.action-breadcrumb .dropdown-toggle::after {
  content: '';
  border: none;
}
.up-down .react-numeric-input {
  width: 100%;
}
.smart-jkc-input input[type='radio'] {
  height: auto !important;
  width: auto;
}
.color-theme {
  color: #e03e6e;
}
.custom-multi .MuiAutocomplete-input {
  min-width: 110px !important;
}
.config-custom .MuiAutocomplete-input {
  min-width: 150px !important;
  width: 220px !important;
}
.pos-rel {
  position: relative;
}
.remove-icon {
  position: absolute;
  top: 8px;
  right: 22px;
  cursor: pointer;
}
.signaturecanvas {
  background: #fff;
  border: 1px solid #ced4da;
  border-radius: 0px;
}
.img-div {
  width: 100%;
  height: 127px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #ced4da;
}
.btn-icon,
.btn-icon:focus,
.btn-icon:hover {
  background-color: #e03e6e;
  border: 1px solid #e03e6e;
  box-shadow: none !important;
  color: #fef6f9;
  display: inline-block;
  right: 0px;
  outline: none;
  padding: 6px;
  position: absolute;
  top: 0px;
}
.sing-pad {
  max-width: 180px;
  position: relative;
}
.follw-check {
  width: 110px;
  position: relative;
  color: #fff;
  font-size: 14px;
  padding: 0px 7px;
}
.custom-width .col-lg-3 {
  max-width: 20% !important;
}

// Search icon scss

.wrapperSearchIcon {
  width: 100%;
  max-width: 31.25rem;
  margin: 6rem auto;
}
.labelSearchIcon {
  font-size: 0.625rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: +1.3px;
  margin-bottom: 1rem;
}
.searchBarSearchIcon {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#searchQueryInputSearchIcon {
  width: 1500px;
  height: 3.8rem;
  background: #f5f5f5;
  outline: none;
  // border-radius: 2.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}
#searchQuerySubmitSearchIcon {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
  &:hover {
    cursor: pointer;
  }
}

.cardSearchIcon {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  // margin: 10px;
  // width: 10%;
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}
.containerSearchIcon {
  padding: 2px 16px;
}

.centerSearchIcon {
  text-align: center;
}

.flex-container-si {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  div {
    padding: 0mm;
    margin: 5px 5px;
    flex: 0 1 15%;
  }
}

.searchIconInputDiv {
  border: 1px solid lightgray;
  padding-left: 7px;
  padding-bottom: 7px;
  .label {
    font-size: 10px;
    left: 7.5px;
    margin: 0px;
    padding: 1;
    top: 2px;
    font-weight: bold;
    user-select: none;
  }
  .iconPDiv {
    width: 100%;
    display: flex;
    .iconName {
    }
    .searchIcon {
      text-align: end;
    }
    .filler {
      flex-grow: 0.94;
    }
  }
  .iconAndName {
    display: flex;
    gap: 5px;
  }
  
}
.colorPickerDiv {
  display: flex;
  gap: 35px;
  justify-content: center;
}
.iconModalComponent {
  width: 1100px;
    height: 500px;
}

.smsProgressModal {
  width: 800px;
    height: 62px;
}
label[for='File'] {
  max-width: 100%;
  height: 100px;
}
.circleColorDiv {
  border-radius: 50%;
  width: 20px;
  height: 20px; 
}
.colorPickerSelect {
  margin-top: 50px;
  display: flex;
  gap: 35px;
  justify-content: center;
}
// Wizard

* {
  font-family: 'Arial', sans-serif;
}
#container {
  position: relative;
  text-align: center;
  max-width: 80%;
  box-sizing: border-box;
  margin: 0 auto;
  padding-top: 30px;
}
.buttons {
  padding: 15px 20px 30px;
}
.step-wizard {
  display: inline-block;
  position: relative;
  width: 85%;
  min-height: 90px;
  .cprogress {
    position: absolute; 
    top: 15px;
    left: 10.5%;
    width: 75%;
  }
  .progressbar {
    position: absolute;
    background-color: #3B5343;
    opacity: 1;
    height: 3px;
    //border: 1px solid e5e6e6;
    width: 0%;
    -webkit-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
    &.empty {
      opacity: 1;
      width: 100%;
      background-color: #d0d0d0;
    }
  }
  ul {
    position: absolute;
    width: 100%;
    list-style-type: none;
    padding: 0;
    left: -2%
  }
  li {
    display: inline-block;
    text-align: center;
    width: 24%;
    & .step {
      text-align: center;
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 3px solid;
      border-color: #d0d0d0;
      background: #ffffff;
      -webkit-transition: background-color 0.6s ease, border-color 0.6s ease;
      -o-transition: background-color 0.6s ease, border-color 0.6s ease;
      transition: background-color 0.6s ease, border-color 0.6s ease; 
    }
    & .title {
      text-decoration: underline;
      width: 100%;
      padding-top: 10px;
      color: #767676;
      -webkit-transition: color 0.6s ease;
      -o-transition: color 0.6s ease;
      transition: color 0.6s ease; 
    }
    &.active {
      .step {
        border-color: #3B5343;
        color: #3b5343;
      }
      .title {
        text-decoration: none;
        color: #3b5343;
        font-weight: bold;
      }
    }
    &.done .title {
      color: #3b5343;
      &:hover {
        color: #3B5343;
      }
    }
    &.done .step {
      color: white;
      background-color: #3B5343;
      border-color: #3B5343;
    }  
    & > .csButton {
      background: none;
      border: none;
      display: block;
      width: 100%;
      color: #777;
      position: relative;
      text-align: center; 
      &:hover {
        & .step {
          border-color: #3b5343;
          background: #eee;
          color: #3b5343;
        }
        & .title {
          color: #3b5343;
        }
      }
    }
  } 
}

@media only screen and (max-width: 1200px) {
  .step-wizard li {
    width: 24%;
  }
}

@media only screen and (max-width: 375px) {
  .step-wizard li {
    width: 22%;
  }
}

li.done button div.step {
    font-size: 0;
}
li.done button div.step::before {
  content:  "\2713 ";
  font-size: 18px;
  
}
.wzContect {
  padding-top: 10px;
}

.jkc-custom-data-table .jkc-custom-data-table-pagination button:hover {
  color: white !important;
}

.jkc-custom-data-table .jkc-custom-data-table-pagination .next-prev-btn:hover {
  svg path {
      fill: white !important;
  }
}