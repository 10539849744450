@keyframes  IndeterminateLinearProgressIndicator {
  0% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(5);
  }
  to {
    transform: scaleX(5) translateX(100%);
  }
}

#loaderBar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  height: 2.75px;
  z-index: 1001;
  overflow: hidden;
}

#bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 25%;
  height: 100%;
  background: white;

  transform-origin: 0 0;
  animation: IndeterminateLinearProgressIndicator 2s infinite;
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
  animation-delay: 0.1s;
}