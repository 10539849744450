// .customer-invoices-card {
//   border: solid 1px #ddd;
//   border-radius: 1em;
//   display: grid;
//   grid-template-columns: 60% auto auto;
//   grid-template-areas:
//     'name name button'
//     'description description price';
//   gap: 1px;
//   font-size: 12px;
//   margin-bottom: 5px;
//   background-color: #eee;
//   padding-left: 10px;
//   padding-right: 10px;
//   min-height: 5em;
// }

.card-customer-container{
  border: solid 1px #ddd;
  border-radius: 1em;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
    'name followupdate'
    'phonemobile vehicle';
    // 'status phonemobile';
  gap: 1px;
  font-size: 12px;
  background-color: #eee;
  padding: 5px;
  min-height: 4em;
  margin-bottom: 5px;
  cursor: pointer;
}
.card-customer-container:hover{
  background-color: #ccc;
}
.card-customer-name {
  grid-area: name;
  font-weight: bolder;
  align-self: end;
}
.card-customer-followupdate {
  grid-area: followupdate;
  font-weight: bolder;
  text-align: end;
  align-self: end;
}
.card-customer-city {
  grid-area: city;
  font-weight: bolder;
  margin: 3px 0 3px 0;
  padding: 3px 0 3px 0;
}
.card-customer-vehicle {
  grid-area: vehicle;
  font-weight: bolder;
  text-align: end;
  margin: 0px 0 0px 0;
  padding: 0px 0 0px 0;
}
.card-customer-status {
  grid-area: status;
  font-weight: bolder;
}
.card-customer-phonemobile {
  grid-area: phonemobile;
  font-weight: bolder;
  margin: 0px 0 0px 0;
  padding: 0px 0 0px 0;
  text-align: start;
}


.leadsTable {
  border: none;
  margin-bottom: 0;
  border-collapse: collapse;
  thead  {
    background: #3c4b64;
    color: #fff;
    tr th {
      padding: 5px;
      font-weight: 600;
      border-bottom: 1px solid #acaebf;
    }
    
  }
  th {
    padding: 5px;
    border: 1px solid #acaebf;
    border-collapse: collapse;
  }
  td {
    padding: 5px;
    border: 1px solid #acaebf;
    border-collapse: collapse;
  }
}