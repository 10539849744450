/* :root {
  --font-size-global: 1rem;
  font-size: var(--font-size-global) !important;
} */
/* ::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */
:root, table, body, button, label,html {
  font-family: "Arial", Courier, monospace !important;
}
CCardHeader {
  border-top: 3px solid red !important;
}
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 300;
  color: #fff;
  font-size: 0.8rem;
  padding: 5px 7px;
  background-color: #4245a8;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 1rem;
  margin-right: 4px;
}
.icon-box {
  color: #000;
}
.input-label-readOnly {

  outline:none;
  
}