.item-number-input { 
  text-align: end;
}

// INPUT FIELD
.input-field-root.upscaled {
  min-height: 50px;
}

// INPUT RADIO GLOBAL
input[type=radio] { border: 0px; width: 20px; height: 2em; }
input[type=radio]:checked:after {
  background-color: #CB8306;
  color: #FAFAFA;
}

#radio-appointment input[type="radio"] {
  background-color: #ddd;
  border-radius: 100%;
  cursor: pointer;
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  position: relative;
  // width: 20px;
  -webkit-appearance: none;
}
#radio-appointment input[type="radio"]:checked {
  background-color: rgb(0, 79, 250);
  border-radius: 100%;
  cursor: pointer;
  display: inline-block;
  height: 1.5em;
  width: 1.5em;
  position: relative;
  // width: 20px;
  -webkit-appearance: none;
}

#radio-opc-2 input[type="radio"] {
  display: none;
}

#radio-opc-2 label {
  display: inline-block;
  // margin: 4px;
  // padding: 8px;
  background-color: #a09e9e;
  color: #fff;
  width: calc(50% - 8px);
  // min-width: 100px;
  cursor: pointer;
}

#radio-opc-2 label:hover {
  background-color: #888888;
}

#radio-opc-2 input[type="radio"]:checked + label {
  background-color: #252525 !important;
  color: #fff;
}




.radio-group-label {
  color: #000;
  font-size: 10px;
  border: 1px solid #ccc;
  padding-top: 2px;
  padding-left: 8px;
  height: 50px;
}
.radio-group-ts {
  padding-top: unset;
  display: flex;
  border: 0px !important;
  padding-left: 0;
  margin: 0;
  text-align: left;
}
.radio-group-ts-label {
  display:flex; 
  align-items: center;
  font-size: 15px;
  align-content: start;
}
.radio-group-ts-value {
  margin-left: 5px;
}

.header-input-check {
  border-radius: 5px;
  background-color: #ddd;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.header-input-check-isFollow {
  margin-right: 10px;
}
.header-input-check-noFollow {
  margin-right: 144px;
}
.header-input-date {
  border: inherit;
  color: rgb(42, 52, 70);
  background-color: #ddd;
  height: 33px;
}

.custom-switch {
  display: flex !important;
  padding: 0.47rem 0.25rem !important;
}