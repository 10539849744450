:root {
  --border-radius-cropper-component: 0%;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}
.cropper-crop-box,
.cropper-view-box {
  border-radius: var(--border-radius-cropper-component);
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}
/*test*/
/* body {
  background: whitesmoke;
  font-family: 'Open Sans', sans-serif;
}
.container {
  max-width: 15rem;
  
}
h1 {
  font-size: 20px;
  text-align: center;
  margin: 20px 0 20px;
}
h1 small {
  display: block;
  font-size: 15px;
  padding-top: 8px;
  color: gray;
} */
.avatar-upload {
  position: relative;
  max-width: 24rem !important;
  margin: auto;
}
.avatar-upload .avatar-edit {
  position: absolute;
  right: 65px;
  z-index: 1;
  top: 15px;
}
.avatar-upload .avatar-edit input {
  display: none;
}
.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 32px;
  height: 32px;
  color: black;
  text-align: center;
  border-radius: 100%;
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.479);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}
.avatar-upload .avatar-edit input + label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
} /*
.avatar-upload .avatar-edit input + label:after {
  content: "";
  font-family: 'FontAwesome';
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
} */
.avatar-upload .avatar-preview {
  width: 24rem;
  height: 24rem;
  position: relative;
 
}
.avatar-upload .avatar-preview > div {
  width: 24rem;
  height: 24rem;
  border-radius: var(--border-radius-cropper-component);
  
  background-size: cover;
  background-repeat: no-repeat;
  
}
.custom-preview {
  max-width: 500px !important;
}
.custom-preview .avatar-preview {
  width: 500px !important;
  height: 200px !important;
}
.custom-preview .avatar-preview > div {
  width: 500px !important;
  height: 200px !important;
  background-size: contain !important;
  background-repeat: no-repeat;
}

