.external-inspector-card {
  border-color: #ddd;
  border-style: solid;
  border-radius: 1em;
  border-width: 1px;
  display: grid;
  width: 100%;
  grid-template-columns: 42px auto auto;
  grid-template-areas:
    'name name name'
    'avatar description description'
    'avatar description description';
    // 'avatar description description price';
  gap: 1px;
  font-size: 12px;
  background-color: #eee;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 10em;
  cursor: pointer;
}

.external-inspector-card .item-name { 
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 2px;
  grid-area: name; 
  font-size: 14px;
  font-weight: bold;
  color: #000;
  align-self: end;
  margin-left: 12px;
}
.external-inspector-card .item-btn { 
  grid-area: btn; 
  align-self: center;
}
.external-inspector-card .item-price { 
  grid-area: price; 
  font-size: 14px;
  color: #000;
  text-align: end;
  align-self: start;
  font-weight: bold;
  padding-right: 2px;
}
.external-inspector-card .item-description { 
  grid-area: description; 
  color: #000;
  margin-left: 13px;
}
.external-inspector-card .item-avatar { 
  grid-area: avatar; 
  // border-radius: 5px; 
  background-color: transparent;
  align-self: start;
  text-align: right;
  cursor: pointer;
}